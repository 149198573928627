import { aberasAPI } from '../../App';

import { IFactura } from "../../utils/interfaces";


//Llamadas relacionadas con el login.
export const login = (userInfo: { email: string, password: string }) => aberasAPI.post("/authentication", userInfo);
export const checkUser = () => aberasAPI.get('/authentication');
export const logout = () => aberasAPI.delete('/authentication');

//Llamadas relacionadas con los negocios.
export const getNegocios = (idCliente) => aberasAPI.get('/negocios/fetchByCliente/' + idCliente);

//Llamadas para facturas.
export const searchFacturas = async (fechaInicio, fechaFin, negocios, gruposGastos, proveedores): Promise<{ totalPagadas: string, totalNoPagadas: string, facturas: IFactura[] }> => {
    let res = await aberasAPI.get('/gastos/fetchGastosAsFactura/', { params: { fInicio: fechaInicio, fFin: fechaFin, negociosIds: negocios, gruposGastos: gruposGastos, proveedores: proveedores } });
    return res.data.data;
};
export const payFactura = (factura) => {
    return aberasAPI.put('/gastos/managePaid/' + (factura as IFactura)._id);
}
//Llamadas para grupos de gastos y proveedores.
export const getGrupoGastos = async (negocios): Promise<string[]> => {
    let res = await aberasAPI.get('/negocios/fetchGruposGastoByNegocios/', { params: { negociosIds: negocios } });
    return res.data.data;
};

export const getProveedores = async (negocios, grupos): Promise<string[]> => {
    let res = await aberasAPI.get('/proveedores/fetchByNegociosAndGruposGasto', { params: { negociosIds: negocios, gruposGastos: grupos } });
    return res.data.data;
};


//Llamadas para proveedores
export const searchGastosProveedores = async (fInicio, fFin, negociosIds, gruposGastos, proveedores): Promise<{ gastos: [], porcentajeIngresos: number }> => {
    let res = await aberasAPI.get('/gastos/fetchByNegociosFechasAndProveedores/', { params: { fInicio: fInicio, fFin: fFin, negociosIds: negociosIds, gruposGastos: gruposGastos, proveedores: proveedores } });
    return res.data.data;
};


