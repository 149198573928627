import React, {FunctionComponent, useState, useMemo, useCallback, useEffect} from "react";
import styles from './Loader.module.css';
import logoVertAmarillo from '../../assets/logo_vert_amarillo.svg';

type Props = {
    instance:any
}
const Loader: FunctionComponent<Props> = ({instance}) => {

    const [counter, setCounter] = useState(0);

    //Contadores para el loader
    const inc = useCallback(() => {
        setCounter(counter => counter + 1)
    }, [setCounter]);
    const dec = useCallback(() => setTimeout(()=>setCounter(counter => counter - 1),100), [setCounter]);

    //Interceptores de axios
    const interceptors = useMemo(() => ({
        request: config => {inc(); return config},
        response: response => {dec(); return response},
        error: error => {dec(); return Promise.reject(error)}
    }), [inc, dec]);

    useEffect(() => {
        // add request interceptors
        const reqInterceptor = instance.interceptors.request.use(interceptors.request, interceptors.error);
        // add response interceptors
        const resInterceptor =  instance.interceptors.response.use(interceptors.response, interceptors.error);
        return () => {
            // remove all intercepts when done
            instance.interceptors.request.eject(reqInterceptor);
            instance.interceptors.response.eject(resInterceptor);
        };
    }, [interceptors]);


    if (counter > 0)
        return  <div className={styles.loaderContainer}>
        <img src={logoVertAmarillo} alt={'imagen logo carga'}/>
        <div className={styles.loaderBar}>
            <div className={styles.loaderBarLoading}></div>
        </div>
    </div>
    return <div />
}

export default Loader;
