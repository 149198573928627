import UserContext from "../server/context/UserContext";
import React, { useContext } from 'react';
import {Redirect, Route} from 'react-router-dom';
import {Layout} from "../components/Layout/Layout";

export const PrivateRoute  = ({component,...rest}) => {

    const {user} = useContext(UserContext);

    const Authentication = (props)  => {
        return user ? <Route {...rest} render={() =>
            <Layout>
                {props.component}
            </Layout>
        } /> : <Redirect to={"/login"} />
    };

    return (
        <UserContext.Consumer>
            {
                ({user}) => <Authentication component={component} user={user}/>
            }
        </UserContext.Consumer>
    )
};
