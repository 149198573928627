class Indice {
  constructor(id, fecha, baseImponibe, iva, totalIngresos) {
    this.id = id;
    this.fecha = fecha;
    this.baseImponibe = baseImponibe;
    this.iva = iva;
    this.totalIngresos = totalIngresos;
  }
}

export default Indice;