import React, {useContext, useEffect, useState} from 'react';
import SearchBarSelector from "../SearchBar/SearchBarSelector";
import NegocioContext, {Negocio} from "../../server/context/NegociosContext";
import {getNegocios} from "../../server/api/aberas";
import UserContext from "../../server/context/UserContext";


const NegociosSearch = () => {

    const { user } = useContext(UserContext);
    const {negocios, changeNegocios} = useContext(NegocioContext);

    const [negociosDisponibles, setNegociosDisponibles] = useState<Negocio[]>([]);

    useEffect(()=>{
        if(user){
            getNegocios(user.cliente).then(res => {
                if(res.data.data){
                    let negociosNames = res.data.data.map(negocio => {return {id:negocio._id,nombre:negocio.nombre}});
                    let result = negociosNames.filter(data => !negocios.map(d => d.nombre).includes(data.nombre));
                    setNegociosDisponibles(result);
                }
            });
        }
    },[user, negocios]);

    const handleNegocios = (disponibles, seleccionados) => {
        setNegociosDisponibles(disponibles);
        changeNegocios(seleccionados);
    };

    return (
        <SearchBarSelector selector={handleNegocios} list={negociosDisponibles} selected={negocios} placeHolder={"Introduzca un negocio"} allItems={negociosDisponibles.concat(negocios)}/>
    )
}

export default NegociosSearch;
