import React, { FunctionComponent, useRef, useState, useEffect, Dispatch } from "react";
import DatePicker from "../../DatePicker/Datepicker";
import styles from './InputDate.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
type Props = {
    changeConfirmation?: (setter: Dispatch<any>) => (data: any) => void,
    label?: string,
    dateStartSelected?: string,
    dateEndSelected?: string,
    range: boolean,
    onClose: (fechaInicio: string, fechaFin: string | null) => void,
    input?: boolean,
    error?: boolean
}
const InputDate: FunctionComponent<Props> = (props) => {
    const [startDate, setStartDate] = useState(props.dateStartSelected);
    const [endDate, setEndDate] = useState(props.dateEndSelected);
    const [showingPicker, setShowingPicker] = useState(false);
    const inputRef = useRef<HTMLInputElement>(document.createElement('input'));

    const handleSelectDates = (fechaInicio: string, fechaFin: string | null) => {
        const setter = ({ fechaInicio, fechaFin }) => {
            setStartDate(fechaInicio);
            setEndDate(fechaFin);
        }
        if (props.changeConfirmation) {
            props.changeConfirmation(setter)({ fechaInicio, fechaFin })
        } else {
            setter({ fechaInicio, fechaFin })
        }
        props.onClose(fechaInicio, fechaFin);
        setShowingPicker(false);
    };

    useEffect(() => {
        setStartDate(props.dateStartSelected);
        setEndDate(props.dateEndSelected);
    }, [props.dateStartSelected, props.dateEndSelected])
    return (
        <div className={styles.inputDate} ref={inputRef}>
            {
                !props.input && <React.Fragment>
                    {props.label && <label onClick={() => setShowingPicker(true)}>{props.label}</label>}
                    {!props.label ?
                        <div className={styles.datesPicker} onClick={() => setShowingPicker(true)}>
                            <FontAwesomeIcon icon={faCalendar} onClick={() => setShowingPicker(true)} style={{ marginRight: '15px', fontSize: '1.1em', cursor: 'pointer' }} />
                            <div className={styles.infoDates} style={{cursor:'pointer'}}>
                                <div className={styles.dateElement}>
                                    <label>Desde:</label>
                                    <span>{startDate}</span>
                                </div>
                                {props.range && <div className={styles.dateElement}>
                                    <label>Hasta:</label>
                                    <span>{endDate}</span>
                                </div>}
                            </div>
                        </div>
                        :
                        <div className={styles.dateRange}>
                            <FontAwesomeIcon icon={faCalendar} onClick={() => setShowingPicker(true)} style={{ marginRight: '15px', fontSize: '1.1em', cursor: 'pointer' }} />
                            <span>{startDate}</span>
                            {props.range && <span>- {endDate}</span>}
                        </div>
                    }
                </React.Fragment>
            }
            {
                props.input &&
                <div>
                    {props.label && <label>{props.label}</label>}
                    <div className={styles.datePickerInput} onClick={() => setShowingPicker(true)} >
                        <span>{moment(startDate, 'DD/MM/YYYY').isValid() ? startDate : '-'}</span>
                        <FontAwesomeIcon icon={faCalendar} style={{ marginLeft: '15px', fontSize: '1.1em', cursor: 'pointer' }} />
                    </div>
                </div>
            }
            {
                showingPicker && <DatePicker position={props.input ? 'left' : 'center'} rangeDate={props.range} selectDateEvent={handleSelectDates} elementRef={inputRef.current} setOpenPicker={setShowingPicker} dateStart={props.dateStartSelected} dateEnd={props.dateEndSelected} />
            }
        </div>
    )
};

export default InputDate;
