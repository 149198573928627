import React, { FunctionComponent } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {useSpring, animated} from "react-spring";

type Props = {
    text: string,
    type:'error' | 'success'
}

const Message: FunctionComponent<Props> = (props) => {
    const animatingProps = useSpring({to:{position:'fixed',zIndex:1090,top:0,left:'50%',transform:'translate(-50%, 50px)',opacity:1,padding:'15px 30px',display:'flex',alignItems:'center',color:'var(--black)', backgroundColor:props.type === 'success' ? 'var(--green)' : 'var(--red)'},
                                            from:{transform:'translate(-50%,0px)', opacity:0},
                                            config:{duration:250,tension:125,friction:25,precision:0.1}});

    return (
        <animated.div style={animatingProps}>
            <FontAwesomeIcon icon={props.type === 'success' ? faCheckCircle : faTimesCircle} style={{marginRight:'8px'}}/>
            <span>{props.text}</span>
        </animated.div>
    )
};

export default Message;
