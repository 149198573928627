import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import '../DatePicker/Datepicker';
import './Header.css';
import InputDate from "../Form/InputDate/InputDate";
import DateContext from "../../server/context/DateContext";
import Message from "../Notificacion/Message";
import { useHistory } from 'react-router-dom';
import logoHeader from '../../assets/logo_gest_amarillo.svg';
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faUserCircle, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../server/context/UserContext";
import {logout} from "../../server/api/aberas";

const Header = () => {
    const {fechaPrincipal, fechaComparativa, changeFechaPrincipal, changeFechaComparativa} = useContext(DateContext);
    const [errDateRange, setErrDateRange] = useState({active: false, error: ''});

    const {user, changeUser} = useContext(UserContext);

    const history = useHistory();

    const changeFecha = (fechaInicio, fechaFin, fecha) => {
        if(fecha === 'original'){
            changeFechaPrincipal({fechaInicio:moment(fechaInicio, 'DD/MM/YYYY').unix()*1000, fechaFin:moment(fechaFin, 'DD/MM/YYYY').unix()*1000});
        }
        if(fecha === 'comparativa'){
            changeFechaComparativa({fechaInicio:moment(fechaInicio, 'DD/MM/YYYY').unix()*1000, fechaFin:moment(fechaFin, 'DD/MM/YYYY').unix()*1000});
        }
    };
    useEffect(()=>{
        if(history.location && history.location.pathname && !history.location.pathname.includes("facturas")){
            const rangeA = moment(fechaPrincipal.fechaFin).diff(moment(fechaPrincipal.fechaInicio), 'days');
            const rangeB = moment(fechaComparativa.fechaFin).diff(moment(fechaComparativa.fechaInicio), 'days');
            if(rangeA !== rangeB){
                const d = Math.abs(rangeA - rangeB);
                setErrDateRange({
                    active: true,
                    error: `Los rangos de fecha no son iguales. El rango ${rangeA > rangeB? 'A' : 'B'} tiene ${d.toString().concat(d>1? ' días' : ' día')} más que el rango ${rangeA > rangeB? 'B' : 'A'}.`})
            }
            else{
                setErrDateRange({active: false, error: ''});
            }
        }
    },[fechaPrincipal, fechaComparativa])

    const handleLogout = () => {
        logout().then(() => {
            history.push('/login');
            changeUser(null);
        }).catch(err => console.error(err));
    }
    return (
        <DateContext.Consumer>
            {(dates) => <header>

                <img src={logoHeader} alt={"Logo de aberas gestión"} />
                <div className={"header-dates"}>
                    <div>
                        <InputDate range={true} onClose={(fechaInicio, fechaFin) => changeFecha(fechaInicio,fechaFin,'original')} error={true}
                                   dateStartSelected={moment(dates.fechaPrincipal.fechaInicio).format('DD/MM/YYYY')} dateEndSelected={moment(dates.fechaPrincipal.fechaFin).format('DD/MM/YYYY')}/>
                                   <span>(A)</span>
                    </div>
                    {
                        history.location && history.location.pathname && !history.location.pathname.includes("facturas") && <React.Fragment>
                            <div>
                                <InputDate range={true} onClose={(fechaInicio, fechaFin) => changeFecha(fechaInicio,fechaFin,'comparativa')}
                                           dateStartSelected={moment(dates.fechaComparativa.fechaInicio).format('DD/MM/YYYY')} dateEndSelected={moment(dates.fechaComparativa.fechaFin).format('DD/MM/YYYY')}
                                />
                                <span>(B)</span>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {errDateRange.active && <Message text={errDateRange.error} type={'error'}/>}
                {
                    user && <div className={"header-info"}>
                        <div className={"header-info--name"}>
                            <span>{user.email}</span>
                            <FontAwesomeIcon icon={faUserCircle} color={'var(--yellow)'}/>
                        </div>
                        <div className={"header-info--logout"} onClick={handleLogout} style={{cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faPowerOff} color={'var(--yellow)'} />
                        </div>
                    </div>
                }
            </header>}
        </DateContext.Consumer>
    )
};

export default Header;
