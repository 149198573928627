import React from 'react';
import TypeInfo from '../TypeInfo';
import CSS from 'csstype';



const CmptChartHeader = ({ title, type } : {title?: string, type?: string}) => {

    const box : CSS.Properties = {
        //width: screenWidth,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '12px',
        marginBottom: '12px',
        paddingTop: '10px',
        paddingBottom: '10px'
    };

    return (
        <div style={box}>
            <TypeInfo type={type} title={title} />
        </div>
    );
};

export default CmptChartHeader;
