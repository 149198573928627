import React, {FunctionComponent} from 'react';
import styles from './Table.module.css';

type TableProps = {
    headers:string[],
    propertys:string[],
    data:any[],
    color1?:string,
    color2:string,
    condition?:(item) => boolean,
    rowAction?:(item)=>void
}


const Table : FunctionComponent<TableProps> = (props) => {
    return (
        <table className={styles.tabla}>
            <thead>
            <tr>
                {props.headers.map((header,i) => {
                    return <th key={i}>{header}</th>
                }) }
            </tr>
            </thead>
            <tbody>
            {
                props.data.map((dataItem,i) => {
                    return <tr key={i} onClick={ () => props.rowAction ? props.rowAction(dataItem) : {}} style={{cursor:props.rowAction ? 'pointer' : 'default', color:props.condition ? (props.condition(dataItem) ? props.color1 : props.color2) : "#ffffff"}}>
                        {props.propertys.map((property,i) => {
                            return <td key={property+i}>{dataItem[property]}</td>
                        })}
                    </tr>
                })
            }
            </tbody>
        </table>
    )
};

export default Table;
