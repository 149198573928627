import React, {FunctionComponent,MutableRefObject,  useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import btnStyles from './Button.module.css';

type Props = {
    color: string,
    text: string,
    action?: () => void,
    type?: "button" | "submit" | "reset",
    icon?: IconDefinition,
    styles?: {},
    buttonRef?:MutableRefObject<any>
    disabled?: boolean,
    justIcon?: boolean,
    iconColor?: string
}


const Button: FunctionComponent<Props> = ({ color, text, action, type, icon, styles, disabled = false, buttonRef, justIcon, iconColor }) => {
    const btnRef = useRef(null);

    function getColor(color: string) {
        switch (color) {
            case 'red':
                return { backgroundColor: 'var(--red)' };
            case 'yellow':
                return { backgroundColor: 'var(--yellow)' };
            case 'green':
                return { backgroundColor: 'var(--green)' };
            case 'blue':
                return { backgroundColor: 'var(--blue)' };
            default:
                return { backgroundColor: color }
        }
    }

    return (
        <button ref={buttonRef ? buttonRef : btnRef} disabled={disabled} type={type ? type : 'button'} onClick={action} className={justIcon? `${btnStyles.justIcon}` : `${btnStyles.btn } ${(icon ?  btnStyles.icon : '')}`} style={Object.assign({}, getColor(color), styles)}>
            {icon && <FontAwesomeIcon icon={icon} color={iconColor? iconColor : 'black'} />}
            {!justIcon && <span>{text}</span>}
        </button>
    )
};

export default Button;
