import React, { useContext, useEffect, useState } from 'react';
import Grafica from "../components/Grafica/Grafica";
import { NewGetBusinessIngresos, NewGetBusinessGastos, NewGetBusinessGruposGastos } from '../server/hooks/useAberas';
import CmptTableStats from '../components/Reportes/CmptTableStats';
import DateContext from '../server/context/DateContext';
import NegocioContext from "../server/context/NegociosContext";
import Panel from "../components/Panel/Panel";
import CmptNoDataAvailable from "../components/NoDataAvailable/CmptNoDataAvailable";
const PageReportes = () => {

    const { negocios } = useContext(NegocioContext);
    const { fechaPrincipal, fechaComparativa } = useContext(DateContext);

    const [dataInfo, setDataInfo] = useState<any>({
        ingresosA: { condition: false },
        ingresosB: { condition: false },
        gastosA: { condition: false },
        gastosB: { condition: false },
        gruposGastosA: { condition: false },
        gruposGastosB: { condition: false }
    });

    const UpdateScreen = async () => {
        let temp: any = {};
        temp.ingresosA = await NewGetBusinessIngresos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);
        temp.ingresosB = await NewGetBusinessIngresos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);
        temp.gastosA = await NewGetBusinessGastos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);
        temp.gastosB = await NewGetBusinessGastos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);
        temp.gruposGastosA = await NewGetBusinessGruposGastos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);
        temp.gruposGastosB = await NewGetBusinessGruposGastos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);
        temp.beneficiosA = temp.ingresosA.total - temp.gastosA.total;
        temp.beneficiosB = temp.ingresosB.total - temp.gastosB.total;
        return temp;
    };

    useEffect(() => {
        let mounted = true;
        UpdateScreen().then((info) => {
            if (mounted)
                setDataInfo(info);
        }).catch(() => console.error("error al obtener la info de los reportes"));

        return () => { mounted = false }

    }, [fechaPrincipal, fechaComparativa, negocios]);

    return (
        <div style={{ display: 'grid', justifyContent: 'center' }}>
            {
                negocios.length > 0 && <React.Fragment>
                    <Panel title={{ texto: `Acumulado en el rango (A)`, color: "white" }} styles={{ margin: '16px', maxWidth: 'none' }}>
                        <CmptTableStats
                            condition={dataInfo.ingresosA.condition && dataInfo.gastosA.condition && dataInfo.gruposGastosA.condition}
                            source={{ ingresos: dataInfo.ingresosA, gastos: dataInfo.gastosA, grupos: dataInfo.gruposGastosA.gruposGastos }} />
                    </Panel>
                    <Panel title={{ texto: `Acumulado en el rango (B)`, color: "white" }} styles={{ margin: '16px', maxWidth: 'none' }}>
                        <CmptTableStats
                            condition={dataInfo.ingresosB.condition && dataInfo.gastosB.condition && dataInfo.gruposGastosB.condition}
                            source={{ ingresos: dataInfo.ingresosB, gastos: dataInfo.gastosB, grupos: dataInfo.gruposGastosB.gruposGastos }} />
                    </Panel>

                    {
                        dataInfo.gruposGastosA.gruposGastos && <Grafica title={"% gastos y beneficios respecto gastos (A)"} colorTitle={"var(--blue)"} type={"circle"} anchoGrafica={15} beneficios={dataInfo.beneficiosA} data={dataInfo.gruposGastosA.gruposGastos} ingresos={dataInfo.ingresosA.total} maxWidth={300} id={"circle-gastos-A"} />
                    }
                    {
                        dataInfo.gruposGastosB.gruposGastos && <Grafica title={"% gastos y beneficios respecto gastos (B)"} colorTitle={"var(--blue)"} type={"circle"} anchoGrafica={15} beneficios={dataInfo.beneficiosB} data={dataInfo.gruposGastosB.gruposGastos} ingresos={dataInfo.ingresosB.total} maxWidth={300} id={"circle-gastos-B"} />
                    }

                    {
                        dataInfo.ingresosA.total && <Grafica type={"area"} title={"Ingresos por día en el rango (A)"} colorTitle={"var(--green)"} data={[dataInfo.ingresosA]} maxHeight={400} maxWidth={700} id={"area-ingresos-A"} />
                    }
                    {
                        dataInfo.ingresosB.cantidades && dataInfo.ingresosB.cantidades.length > 0 && <Grafica type={"area"} title={"Ingresos por día en el rango (B)"} colorTitle={"var(--green)"} data={[dataInfo.ingresosB]} maxHeight={400} maxWidth={700} id={"area-ingresos-B"} />
                    }
                    {
                        dataInfo.gruposGastosA.gruposGastos && <Grafica title={"Gastos por día y grupo en el rango (A)"} colorTitle={"var(--red)"} type={"area"} data={dataInfo.gruposGastosA.gruposGastos} maxHeight={400} maxWidth={700} id={"area-gastos-A"} />
                    }
                    {
                        dataInfo.gruposGastosB.gruposGastos && <Grafica title={"Gastos por día y grupo en el rango (B)"} colorTitle={"var(--red)"} type={"area"} data={dataInfo.gruposGastosB.gruposGastos} maxHeight={400} maxWidth={700} id={"area-gastos-B"} />
                    }
                </React.Fragment>
            }
            {
                negocios.length === 0 && <Panel styles={{ margin: '16px' }}><CmptNoDataAvailable color={'var(--red)'} mensaje={"No hay negocios seleccionados"} /></Panel>
            }

        </div>
    );
};



export default PageReportes;
