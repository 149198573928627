import React from 'react';
import CmptProveedorItem from './CmptProveedorItem';
import CmptGenericHeader from '../CmptGenericHeader';
import CmptNoDataAvailable from '../NoDataAvailable/CmptNoDataAvailable';
import CSS from 'csstype';
import { Dark } from '../../styles/temas';
import { formatNumber } from '../../utils/formats';

const CmptProveedoresTable = ({ type, proveedores, setShow }: { type: string, proveedores: any, setShow?: any }) => {
    const { colors } = Dark;

    const table: CSS.Properties = {
        display: 'flex',
        width: '60%',
        alignSelf: 'center',
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '30px',
    };
    const headerTxt: CSS.Properties = {
        margin: '1',
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '5px',
        flex: 1,
        color: colors.primary
    };
    return (
        <div style={table}>
            <div style={{ alignItems: 'stretch', flexDirection: 'column' }}>
                <CmptGenericHeader
                    setShow={setShow}
                    title={'TODOS'}
                    type={type}
                    subheader={'% SOBRE TOTAL\nINGRESOS'}
                    data={[formatNumber(parseFloat(proveedores.porcentajeIngresos)) + '%']}
                    total={proveedores.totalGastos}
                />
                {proveedores.gastos.length !== 0 ? <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p style={headerTxt}>PROVEEDOR</p>
                    <p style={headerTxt}>CANTIDAD</p>
                    <p style={headerTxt}>% SOBRE TOTAL{'\n'}INGRESOS</p>
                </div> : <CmptNoDataAvailable />}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingLeft: 4, paddingRight: 4 }} >
                {proveedores.gastos.map((element: any, index: number) => {
                    return <CmptProveedorItem key={element.id + type} proveedor={element} indicator={type === 'DIF'} />
                })}
            </div>
        </div>
    );
};

export default CmptProveedoresTable;
