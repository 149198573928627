import React, {useState, useContext, FunctionComponent} from 'react';
import '../styles/pageLogin.css';
import { login } from "../server/api/aberas";
import Form from "../components/Form/Form";
import Input from "../components/Form/Input/Input";
import UserContext from "../server/context/UserContext";
import Button from "../components/Button/Button";
import Message from "../components/Notificacion/Message";
import {faUser, faLock} from "@fortawesome/free-solid-svg-icons";
import Panel from "../components/Panel/Panel";
import logo from '../assets/logo_vert_black.svg';

type loginInfo={
    email:string,
    password:string
}
const PageLogin : FunctionComponent = () => {

    const { changeUser} = useContext(UserContext);

    const [userInfo, setUserInfo] = useState<loginInfo>({email:'',password:''});
    const [errLogin, setErrLogin] = useState(false);

    const tryLogin = async () => {
        try{
            let loginResponse = await login(userInfo);
            if(loginResponse.data.user){
                changeUser(loginResponse.data.user);
            }
            else
                setErrLogin(true);
        }catch(err){
            setErrLogin(true);
        }
    };

    return (
        <UserContext.Consumer>
        {({user}) => user ? <div></div> :
            <div className={'login-container'}>
                <Panel title={{texto:"Login", color:"var(--yellow)"}} styles={{margin:'auto'}}>
                    <Form onSubmit={tryLogin}>
                        <Input value={userInfo.email} setValue={(value) => setUserInfo({...userInfo, email:value})} type={'text'} name={'user-email'} icon={faUser}/>
                        <Input value={ userInfo.password } setValue={(value) => setUserInfo({...userInfo, password:value})} type={'password'} name={'user-password'} icon={faLock}/>
                        <Button color={'yellow'} text={"Login"} type={'submit'}/>
                    </Form>
                </Panel>
                <div style={{height:'100%',backgroundColor:'var(--yellow)', padding:'15px 50px', display:'flex'}}>
                    <img src={logo} alt={'logo de aberas'} style={{margin:'auto'}}/>
                </div>
                {errLogin && <Message text={"Ha ocurrido un error al hacer login"} type={'error'}/>}
            </div>}
    </UserContext.Consumer>
    );
};

export default PageLogin;


