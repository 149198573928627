import React, { FunctionComponent, useState, useContext, useEffect } from 'react';
import { IFactura } from '../utils/interfaces';
import '../styles/global.css'
import Button from '../components/Button/Button';
import DateContext from '../server/context/DateContext';
import NegocioContext from "../server/context/NegociosContext";
import { payFactura, searchFacturas } from "../server/api/aberas";
import Modal from "../components/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Table from "../components/Tables/Table";
import SeleccionesContext from "../server/context/SeleccionesContext";


const PageFacturas: FunctionComponent = () => {

    const { fechaPrincipal } = useContext(DateContext);
    const { negocios } = useContext(NegocioContext);
    const { selecciones } = useContext(SeleccionesContext);
    const [totales, setTotales] = useState<{
        totalPagadas: string;
        totalNoPagadas: string
    }>({ totalPagadas: '', totalNoPagadas: '' })
    const [facturas, setFacturas] = useState<IFactura[]>([]);
    const [facturaSelected, setFacturaSelected] = useState<IFactura | boolean>(false);
    const [pagadas, setPagadas] = useState(true);
    const [noPagadas, setNoPagadas] = useState(true);

    const [update, setUpdate] = useState(false);
    useEffect(() => {
        if (selecciones.proveedores.length > 0) {
            searchFacturas(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios.map(x => x.id), selecciones.gruposDeGastos, selecciones.proveedores).then(res => {
                setTotales({ totalPagadas: res.totalPagadas, totalNoPagadas: res.totalNoPagadas })
                let newFacturas: IFactura[] = res.facturas;
                if (!pagadas) newFacturas = newFacturas.filter(factura => factura.pagado !== true);
                if (!noPagadas) newFacturas = newFacturas.filter(factura => factura.pagado !== false);
                setFacturas(newFacturas);
            });
        } else {
            setFacturas([]);
        }
    }, [selecciones.proveedores, fechaPrincipal, pagadas, noPagadas, update]);


    const handlePayFactura = () => {
        if (facturaSelected) {
            payFactura(facturaSelected).then(() => {
                setFacturaSelected(false);
                setUpdate(!update);
            }).catch(() => console.error("Ha ocurrido un error al pagar la factura"));
        }
    };

    const handleClickFactura = (factura) => {
        setFacturaSelected(factura);
    };


    return (
        <React.Fragment>
            {
                selecciones.proveedores.length > 0 ? <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', marginTop: 30, marginBottom: 30, alignSelf: 'stretch' }}>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <Button text="PAGADAS" color={!pagadas ? '#c1c1c1' : "var(--green)"} action={() => setPagadas(!pagadas)} />
                            <div style={{
                                display: 'grid',
                                justifyItems: 'center',
                                border: "2px solid var(--green)",
                                padding: '10px',
                                color: 'var(--green)',
                                fontWeight: 'bold'
                            }}>
                                <span >TOTAL</span>
                                <span style={{ marginTop: '5px' }}>{totales.totalPagadas}</span>
                            </div>
                        </div>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <Button text="NO PAGADAS" color={!noPagadas ? '#c1c1c1' : "var(--red)"} action={() => setNoPagadas(!noPagadas)} />
                            <div style={{
                                display: 'grid',
                                justifyItems: 'center',
                                border: "2px solid var(--red)",
                                padding: '10px',
                                color: 'var(--red)',
                                fontWeight: 'bold'
                            }}>
                                <span>TOTAL</span>
                                <span style={{ marginTop: '5px' }}>{totales.totalNoPagadas}</span>
                            </div>
                        </div>

                    </div>
                    <div style={{ padding: '30px' }}>
                        <Table headers={["Proveedor", "Día", "Fecha", "Importe"]} propertys={["proveedor", "dia", "fecha", "cantidad"]} data={facturas} color1={"var(--green)"} color2={"var(--red)"} condition={(factura) => factura.pagado} rowAction={handleClickFactura} />
                    </div>

                    {
                        !!facturaSelected && <Modal open={!!facturaSelected} setOpen={setFacturaSelected}>
                            <p>Atención</p>
                            <p>{!(facturaSelected as IFactura).pagado ? "¿Está seguro que desea marcar esta factura como pagada?" : `¿Está seguro que desea poner esta factura como NO pagada?`}</p>
                            <p>
                                <span>Proveedor: <b>{(facturaSelected as IFactura).proveedor}</b></span>
                                <span style={{ marginLeft: '30px' }}><FontAwesomeIcon icon={faCalendarAlt} /> <b>{(facturaSelected as IFactura).fecha}</b></span>
                            </p>
                            <p>
                                <Button color={"red"} text={"Cancelar"} action={() => setFacturaSelected(false)} />
                                <Button color={"yellow"} text={"Confirmar"} action={handlePayFactura} />
                            </p>
                        </Modal>
                    }

                </React.Fragment>
                    :
                    <p>Debe seleccionar al menos un proveedor para mostrar datos.</p>
            }

        </React.Fragment>
    );
};

export default PageFacturas;
