import React, { FunctionComponent } from 'react';
import inputStyles from '../Input/Input.module.css';

type InputType = 'radio' | 'checkbox';

type Props = {
    type: InputType,
    checked?: boolean,
    name: string,
    label: string,
    superLabel?: string,
    rounded?: boolean,
    onChange: () => void,
    disabled?: boolean
}

const Checkbox: FunctionComponent<Props> = ({ type, checked, name, label, superLabel, rounded, onChange, disabled = false }) => {

    return (
        <label className={`${inputStyles.checkContainer} ${disabled ? inputStyles.disabled : ''}`}>
            {superLabel && <span>{superLabel}</span>}
            <span style={{ textTransform: 'uppercase',fontWeight:'bold' }}>{label}</span>
            <input disabled={disabled} type={type} checked={checked} name={name} onChange={onChange} />
            <span className={`${inputStyles.checkmark} ${(type === 'radio' || rounded) ? inputStyles.rounded : ''}`}></span>
        </label>
    )
}

export default Checkbox;
