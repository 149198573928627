import React from 'react';

import './Row.css';
import { Dark } from '../../styles/temas';

const TableHeader = ({ screen }) => {
    const { colors } = Dark;
    return (
        <div className="row-container" style={{ backgroundColor: colors.darkGray }}>
            <div className="fecha-container" >
                <p className="txtGeneric" style={{ color: colors.primary }}>FECHA</p>
            </div>
            <div className="base-container" >
                <p className="txtGeneric" style={(screen === 'Ingresos') ?
                    { color: colors.accentIngresos } : (screen === 'Gastos') ?
                        { color: colors.accentGastos } : { color: colors.beneficios }
                }>BASE{'\n'}IMPONIBLE</p>
            </div>
            <div className="iva-container" >
                <p className="txtGeneric" >IVA</p>
            </div>
            <div className="total-container" >
                <p className="txtGeneric" style={(screen === 'Ingresos') ?
                    { color: colors.accentIngresos } : (screen === 'Gastos') ?
                        { color: colors.accentGastos } : { color: colors.beneficios }
                }>TOTAL{'\n'}IVA INC.</p>
            </div>
        </div>
    );

};

export default TableHeader;