import React from 'react';

import CmptNoDataAvailable from '../NoDataAvailable/CmptNoDataAvailable';
import CmptChartHeader from '../Reportes/CmptChartHeader';
import GenericTable from './GenericTable';
import { Dark } from '../../styles/temas';
import IngresosMedios from './IngresosMedios';


const BodyTables = ({ loading, negocios, currentNegocios, view, data, screen, condition }) => {
    const { colors } = Dark;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '5px', justifyContent: 'center', width: '60%' }}>
                <div className="tableContainer">
                    <CmptChartHeader type={'A'} title={(currentNegocios.length === 0) ?
                        'Seleccione un negocio' : (currentNegocios.length === 1) ?
                            currentNegocios[0].nombre : (negocios.length === 0) ?
                                'Todos los negocios' : 'Varios'} />
                    <IngresosMedios
                        screen={screen}
                        data={(data) ? data[3] : ''} type={'A'}
                        subheader={screen.toUpperCase() + ' MEDIOS'}
                        total={(data) ? data[4] : ''}
                        subheaderTotal={screen.toUpperCase() + ' TOTALES'}
                    />
                    {(condition) ? <GenericTable data={data[0]} view={view} screen={screen} dif={false} /> :
                        <CmptNoDataAvailable color={screen === 'Ingresos' ? colors.ingresos :
                            screen === 'Gastos' ? colors.gastos : colors.beneficios}
                            mensaje={(loading) ? 'Cargando datos...' : 'No hay informacion disponible :('}
                        />}

                </div>
                <div className="tableContainer">
                    <CmptChartHeader type={'B'} title={(currentNegocios.length === 0) ?
                        'Seleccione un negocio' : (currentNegocios.length === 1) ?
                            currentNegocios[0].nombre : (negocios.length === 0) ?
                                'Todos los negocios' : 'Varios'} />
                    <IngresosMedios
                        screen={screen}
                        data={(data) ? data[3] : ''}
                        type={'B'}
                        subheader={screen.toUpperCase() + ' MEDIOS'}
                        total={(data) ? data[5] : ''}
                        subheaderTotal={screen.toUpperCase() + ' TOTALES'}
                    />
                    {(condition) ? <GenericTable data={data[1]} view={view} screen={screen} dif={false} /> :
                        <CmptNoDataAvailable color={screen === 'Ingresos' ? colors.ingresos :
                            screen === 'Gastos' ? colors.gastos : colors.beneficios}
                            mensaje={(loading) ? 'Cargando datos...' : 'No hay informacion disponible :('}
                        />}
                </div>
                <div className="tableContainer">
                    <CmptChartHeader type={'Diferencia'} title={(currentNegocios.length === 0) ?
                        'Seleccione un negocio' : (currentNegocios.length === 1) ?
                            currentNegocios[0].nombre : (negocios.length === 0) ?
                                'Todos los negocios' : 'Varios'} />
                    <IngresosMedios screen={screen} data={(data) ? data[3] : ''} type={'Diferencia'}
                        subheader={screen.toUpperCase() + ' MEDIOS'}
                        total={(data) ? data[6] : ''}
                        subheaderTotal={screen.toUpperCase() + ' TOTALES'}
                    />
                    {(condition) ? <GenericTable data={data[2]} view={view} screen={screen} dif={true} /> :
                        <CmptNoDataAvailable color={screen === 'Ingresos' ? colors.ingresos :
                            screen === 'Gastos' ? colors.gastos : colors.beneficios}
                            mensaje={(loading) ? 'Cargando datos...' : 'No hay informacion disponible :('}
                        />}
                </div>
            </div>
        </div>
    );
};

export default BodyTables;