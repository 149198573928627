import React from 'react';
import Menu from "./Menu";
import Header from "../Header/Header";
import NegociosSearch from "./NegociosSearch";
import {useHistory} from 'react-router-dom';
import GruposSearch from "./GruposSearch";
import ProveedoresSearch from "./ProveedoresSearch";
import {SeleccionesProvider} from "../../server/context/SeleccionesContext";
import CmptCopyright from "../CmptCopyright";

export const Layout = (props) => {

    const history = useHistory();

    return (
        <React.Fragment>
            <Menu/>
            <Header/>
            <main id={"main"}>
                <NegociosSearch />
                {
                    ( history.location.pathname.includes("proveedores") || history.location.pathname.includes("facturas") ) ?
                        <SeleccionesProvider>
                            <GruposSearch/>
                            <ProveedoresSearch/>
                            {props.children}
                        </SeleccionesProvider>
                        :
                        <React.Fragment>{props.children}</React.Fragment>
                }
                <CmptCopyright color={'white'} />
            </main>
        </React.Fragment>
    )
}
