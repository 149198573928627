import React, {useContext, useEffect, useState} from 'react';
import {getGrupoGastos} from "../../server/api/aberas";
import SearchBarSelector from "../SearchBar/SearchBarSelector";
import NegocioContext from "../../server/context/NegociosContext";
import SeleccionesContext from "../../server/context/SeleccionesContext";


const GruposSearch = () => {
    const {negocios} = useContext(NegocioContext);
    const {selecciones, changeSelecciones} = useContext(SeleccionesContext);

    const [gruposDisponibles, setGruposDisponibles] = useState<string[]>([]);
    const [allGrupos, setAllGrupos] = useState<string[]>([]);

    useEffect(()=>{
        if(negocios.length>0){
            let negociosIds = negocios.map(x => x.id);
            getGrupoGastos(negociosIds).then(gruposDeGasto => {
                setAllGrupos(gruposDeGasto);
                let grpAv = gruposDisponibles.filter(grupo => gruposDeGasto.includes(grupo));
                let grpSel = selecciones.gruposDeGastos.filter(grupo => gruposDeGasto.includes(grupo));
                let newGrupos = gruposDeGasto.filter(grupo => !grpSel.includes(grupo) && !grpAv.includes(grupo));

                changeSelecciones({...selecciones,gruposDeGastos:grpSel.concat(newGrupos)});
                setGruposDisponibles(grpAv);
            }).catch(() => console.error("Error al obtener los grupos de gastos"));
        }else{
            changeSelecciones({...selecciones,gruposDeGastos:[]});
        }
    },[negocios]);

    const handleCurrentGrupos = (disponibles,seleccionados ) => {
        setGruposDisponibles(disponibles);
        changeSelecciones({...selecciones,gruposDeGastos:seleccionados});
    };

    return <SearchBarSelector placeHolder={'Selecciona un grupo de gastos'} list={gruposDisponibles} selected={selecciones.gruposDeGastos} selector={handleCurrentGrupos} allItems={allGrupos}/>
};

export default GruposSearch;
