import React, { useContext, useState, useEffect } from 'react';
import BodyTables from '../components/Tables/BodyTables';
import DateContext from '../server/context/DateContext';
import { creaBeneficios } from '../components/Tables/Calculos';
import {  NewGetBusinessIngresos, NewGetBusinessGastos } from '../server/hooks/useAberas';
import GroupSelector from '../components/Tables/GroupSelector';
import NegocioContext from "../server/context/NegociosContext";

const PageBeneficios = () => {

    const { negocios } = useContext(NegocioContext);
    const { fechaPrincipal, fechaComparativa } = useContext(DateContext);
    const [loadingData, setLoadingData] = useState(false);
    const [action, setAction] = useState({ type: '', payload: '' });
    const [dataInfo, setDataInfo] = useState<any>({
        DATA: null,
        condition: false,
        selectedTypeView: 'Diario'
    });


    const UpdateScreen = async (payload) => {
        let temp: any = {};

        temp.ingresosA = await NewGetBusinessIngresos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);


        temp.ingresosB = await NewGetBusinessIngresos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);
        temp.gastosA = await NewGetBusinessGastos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);
        temp.gastosB = await NewGetBusinessGastos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);

        let stamp = creaBeneficios(temp.ingresosA, temp.ingresosB, temp.gastosA, temp.gastosB, fechaPrincipal.fechaInicio, fechaComparativa.fechaInicio, payload);
        setDataInfo({ DATA: stamp, condition: true, selectedTypeView: payload });
    };

    useEffect(() => {
        setLoadingData(false);
    }, [dataInfo]);

    useEffect(() => {
        if (loadingData) {
            switch (action.type) {
                case 'update':
                    UpdateScreen(action.payload);
                    return;
                case 'changeView':
                    UpdateScreen(action.payload);
                    return;
            }
            setAction({ type: '', payload: '' });
        }

    }, [loadingData]);

    useEffect(() => {
        if (negocios.length === 0) {
            setDataInfo({
                ...dataInfo, condition: false, DATA: null
            });
            return;
        }
        setLoadingData(true);
        setAction({ type: 'update', payload: dataInfo.selectedTypeView });
    }
        , [negocios, fechaPrincipal, fechaComparativa]);

    return (
        <div style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <GroupSelector view={dataInfo.selectedTypeView} setView={(newView) => {
                setLoadingData(true);
                setAction({ type: 'changeView', payload: newView });
            }} />
            <BodyTables loading={loadingData} negocios={negocios} currentNegocios={negocios} view={dataInfo.selectedTypeView} data={dataInfo.DATA} screen={'Beneficios'} condition={dataInfo.condition} />
        </div>
    );
};

export default PageBeneficios;
