import React from 'react';
import {Dark} from '../../styles/temas';
import myStyles from './CmptNoDataAvailable.module.css';
import CSS from 'csstype';

const CmptNoDataAvailable = ({mensaje, color}:{mensaje? : string, color? : string}) => {
    const { colors } = Dark;

    const box: CSS.Properties = {
        display: 'flex',
        height: '200px',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderColor: colors.fireOpal
    };
    const corner: CSS.Properties = {
        width: '50px',
        height: '25px',
        borderColor: color? color : colors.fireOpal
    };
    const text: CSS.Properties = {
        textAlign: 'center',
        paddingRight: '25px',
        paddingLeft: '25px',
        fontSize: '18px',
        color: color? color : colors.fireOpal
    };

    return (
        <div style={box}>
            <div style={{width: '100%', height: '100%', backgroundColor: (color? color : colors.fireOpal) + '33', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',}}>
                <div className={myStyles.leftCorner} style={corner}></div>
                    <p style={text}>{mensaje? mensaje : 'No hay informacion disponible :('} </p>
                <div className={myStyles.rightCorner} style={corner}></div>
            </div>
        </div>);
};

export default CmptNoDataAvailable;