import './Menu.css';
import React, {useState} from "react";
import {NavLink} from 'react-router-dom';
import gsap from 'gsap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Menu = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const handleOpenMenu = () => {
        if(menuOpen){
            gsap.to('.menu-container', {duration:.3, x:-145, ease:'linear'});
            gsap.to('#main',{duration:.6, padding:"75px 15px 0 calc(75px + 0.75em)"});
        }
        if(!menuOpen){
            gsap.to('.menu-container', {duration:.3, x:0, ease:'lineal'});
            gsap.to('#main',{duration:.6,ease:'power3.out', padding:"75px 15px 0 calc(220px + 0.75em)"});
        }
        setMenuOpen(!menuOpen);
    };

    return (
        <div className={'menu-container'}>
            <FontAwesomeIcon style={{position:'absolute', right:'15px', top:'15px', cursor:'pointer', transform:'translateX(-50%)', color:'var(--yellow)', fontSize:'2.3em'}} onClick={handleOpenMenu}  icon={menuOpen ? faChevronLeft : faChevronRight}/>
            <NavLink className={'menu--item'} exact to={"/"} isActive={(match,location) => {if(location.pathname.includes('reportes') || location.pathname === '/') return true}} title={"Reportes"}>
                <span>Inicio</span>
                <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" >
                    <g>
                        <g>
                            <path d="M506.188,236.413L297.798,26.65c-0.267-0.27-0.544-0.532-0.826-0.786c-22.755-20.431-57.14-20.504-79.982-0.169
                                    c-0.284,0.253-0.56,0.514-0.829,0.782L5.872,236.352c-7.818,7.804-7.831,20.467-0.028,28.285
                                    c7.804,7.818,20.467,7.83,28.284,0.028L50,248.824v172.684c0,44.112,35.888,80,80,80h72c11.046,0,20-8.954,20-20v-163h70v163
                                    c0,11.046,8.954,20,20,20h70c44.112,0,80-35.888,80-80c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20
                                    c0,22.056-17.944,40-40,40h-50v-163c0-11.046-8.954-20-20-20H202c-11.046,0-20,8.954-20,20v163h-52c-22.056,0-40-17.944-40-40
                                    v-212c0-0.2-0.003-0.399-0.009-0.597L243.946,55.26c7.493-6.363,18.483-6.339,25.947,0.055L422,208.425v113.083
                                    c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20v-72.82l15.812,15.917c3.909,3.935,9.047,5.904,14.188,5.904
                                    c5.097,0,10.195-1.937,14.096-5.812C513.932,256.912,513.974,244.249,506.188,236.413z" fill={"#ffffff"}/>
                        </g>
                    </g>
                </svg>
            </NavLink>
            <NavLink exact to={"/ingresos"} className={'menu--item'} title={"Ingresos"}>
                <span>Ingresos</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.205" height="27.595" viewBox="0 0 17.205 27.595">
                    <g id="Grupo_715" data-name="Grupo 715" transform="translate(-55.8)">
                        <g id="Grupo_711" data-name="Grupo 711" transform="translate(55.8)">
                            <g id="Grupo_710" data-name="Grupo 710">
                                <g id="Grupo_709" data-name="Grupo 709">
                                    <path id="Trazado_413" data-name="Trazado 413" d="M86.578,13.593A6.8,6.8,0,1,0,79.8,6.778,6.778,6.778,0,0,0,86.578,13.593Zm0-12.4a5.586,5.586,0,1,1-5.586,5.586A5.579,5.579,0,0,1,86.578,1.192Z" transform="translate(-77.566)" fill="#ffffff"/>
                                    <path id="Trazado_414" data-name="Trazado 414" d="M72.409,156.021h-.484V154.2a.588.588,0,0,0-.6-.6H59.077a.588.588,0,0,0-.6.6v2.421a.588.588,0,0,0,.6.6h.484v1.229H58.63a.588.588,0,0,0-.6.6v1.825H56.4a.588.588,0,0,0-.6.6v2.421a.588.588,0,0,0,.6.6h.67V166.3a.588.588,0,0,0,.6.6H69.914a.588.588,0,0,0,.6-.6v-2.421a.588.588,0,0,0-.6-.6h-.67v-1.229h1.639a.588.588,0,0,0,.6-.6v-1.825h.931a.588.588,0,0,0,.6-.6v-2.421A.612.612,0,0,0,72.409,156.021Zm-12.736-1.192h11.06v1.229H59.673Zm-2.681,8.416v-1.229h11.06v1.229H56.992Zm12.326,1.192v1.229H58.258v-1.229h11.06Zm.968-3.575H59.226v-1.229h11.06Zm1.527-2.421H60.753v-1.229h11.06Z" transform="translate(-55.8 -139.3)" fill="#ffffff"/>
                                </g>
                            </g>
                        </g>
                        <g id="_0cADkt" data-name="0cADkt" transform="translate(61.178 3.159)">
                            <g id="Grupo_712" data-name="Grupo 712" transform="translate(0)">
                                <path id="Trazado_415" data-name="Trazado 415" d="M117.653,35.495v.325q0,2.367,0,4.734a2.041,2.041,0,0,1,0,.243.451.451,0,0,1-.9.009,1.861,1.861,0,0,1-.006-.242q0-2.379,0-4.759v-.332c-.226.231-.4.409-.573.584q-.9.9-1.8,1.8a.451.451,0,0,1-.573.108.408.408,0,0,1-.216-.492.605.605,0,0,1,.158-.262q1.548-1.559,3.1-3.11a.452.452,0,0,1,.7-.007q1.556,1.552,3.107,3.108a.458.458,0,1,1-.653.627q-1.057-1.054-2.112-2.111C117.827,35.659,117.763,35.6,117.653,35.495Z" transform="translate(-113.565 -33.933)" fill="#ffffff" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="0.8"/>
                            </g>
                        </g>
                    </g>
                </svg>

            </NavLink>
            <NavLink exact to={"/facturas"} className={'menu--item'} title={"Facturas"}>
                <span>Facturas</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.413" height="28.97" viewBox="0 0 18.413 28.97">
                    <g id="cuenta" transform="translate(-0.5)">
                        <path id="Trazado_341" data-name="Trazado 341" d="M39.444,196.318v-1.764a.424.424,0,0,0-.424-.424H31.143a.424.424,0,0,0-.424.424v1.764a.424.424,0,0,0,.424.424H39.02A.424.424,0,0,0,39.444,196.318Zm-.849-.424H31.567v-.916H38.6Zm0,0" transform="translate(-28.509 -183.145)" fill="#ffffff"/>
                        <path id="Trazado_342" data-name="Trazado 342" d="M184.358,390.731h5.476a.425.425,0,0,0,.424-.424v-1.765a.424.424,0,0,0-.424-.424h-5.476a.424.424,0,0,0-.424.424v1.765A.424.424,0,0,0,184.358,390.731Zm.424-1.765h4.628v.916h-4.628Zm0,0" transform="translate(-173.055 -366.157)" fill="#ffffff"/>
                        <path id="Trazado_343" data-name="Trazado 343" d="M45.288,148.7H31.143a.424.424,0,1,0,0,.849H45.288a.424.424,0,1,0,0-.849Zm0,0" transform="translate(-28.509 -140.282)" fill="#ffffff"/>
                        <path id="Trazado_344" data-name="Trazado 344" d="M36.276,266.12a.424.424,0,0,0-.424-.424H31.143a.424.424,0,1,0,0,.849h4.708A.424.424,0,0,0,36.276,266.12Zm0,0" transform="translate(-28.509 -250.662)" fill="#ffffff"/>
                        <path id="Trazado_345" data-name="Trazado 345" d="M35.081,303.531H31.143a.424.424,0,1,0,0,.849h3.938a.424.424,0,1,0,0-.849Zm0,0" transform="translate(-28.509 -286.357)" fill="#ffffff"/>
                        <path id="Trazado_346" data-name="Trazado 346" d="M35.081,341.363H31.143a.424.424,0,1,0,0,.849h3.938a.424.424,0,1,0,0-.849Zm0,0" transform="translate(-28.509 -322.049)" fill="#ffffff"/>
                        <path id="Trazado_347" data-name="Trazado 347" d="M248.308,341.363h-1.969a.424.424,0,1,0,0,.849h1.969a.424.424,0,0,0,0-.849Zm0,0" transform="translate(-231.528 -322.049)" fill="#ffffff"/>
                        <path id="Trazado_348" data-name="Trazado 348" d="M248.308,303.531h-1.969a.424.424,0,1,0,0,.849h1.969a.424.424,0,0,0,0-.849Zm0,0" transform="translate(-231.528 -286.357)" fill="#ffffff"/>
                        <path id="Trazado_349" data-name="Trazado 349" d="M248.308,265.7h-1.969a.424.424,0,1,0,0,.849h1.969a.424.424,0,0,0,0-.849Zm0,0" transform="translate(-231.528 -250.662)" fill="#ffffff"/>
                        <path id="Trazado_350" data-name="Trazado 350" d="M18.489,9.983a.424.424,0,0,0,.424-.424V.424A.424.424,0,0,0,18.489,0c-1.345,0-1.458,1.7-2.2,1.7S15.444,0,14.1,0s-1.458,1.7-2.2,1.7S11.055,0,9.706,0s-1.458,1.7-2.2,1.7S6.661,0,5.315,0,3.858,1.7,3.12,1.7,2.274,0,.924,0A.424.424,0,0,0,.5.424V28.545a.424.424,0,0,0,.424.424c1.345,0,1.458-1.7,2.2-1.7s.846,1.7,2.2,1.7,1.458-1.7,2.2-1.7.847,1.7,2.2,1.7,1.458-1.7,2.2-1.7.846,1.7,2.2,1.7,1.458-1.7,2.2-1.7.849,1.7,2.2,1.7a.424.424,0,0,0,.424-.424v-17.4a.424.424,0,1,0-.849,0V27.907c-.424-.468-.732-1.483-1.772-1.483-1.345,0-1.458,1.7-2.2,1.7s-.85-1.7-2.2-1.7-1.458,1.7-2.2,1.7-.846-1.7-2.2-1.7-1.458,1.7-2.2,1.7-.846-1.7-2.2-1.7c-1.039,0-1.347,1.014-1.771,1.483V1.063c.423.468.732,1.483,1.771,1.483,1.345,0,1.458-1.7,2.2-1.7s.846,1.7,2.2,1.7,1.458-1.7,2.2-1.7.847,1.7,2.2,1.7,1.458-1.7,2.2-1.7.849,1.7,2.2,1.7c1.039,0,1.348-1.015,1.771-1.483v8.5A.424.424,0,0,0,18.489,9.983Zm0,0" transform="translate(0 0)" fill="#ffffff"/>
                        <path id="Trazado_351" data-name="Trazado 351" d="M45.713,78.12a.424.424,0,0,0-.424-.424H35.081a.424.424,0,0,0,0,.849h9.783v.916h-13.3v-.916H33.5a.424.424,0,0,0,0-.849H31.143a.424.424,0,0,0-.424.424v1.765a.424.424,0,0,0,.424.424H45.288a.424.424,0,0,0,.424-.424Zm0,0" transform="translate(-28.509 -73.299)" fill="#ffffff"/>
                    </g>
                </svg>

            </NavLink>
            <NavLink exact to={"/beneficios"} className={'menu--item'} title={"Beneficios"}>
                <span>Beneficios</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="25.25" height="28" viewBox="0 0 25.25 28">
                    <g id="Icon_ionic-ios-stats" data-name="Icon ionic-ios-stats" transform="translate(-5.125 -4)">
                        <path id="Trazado_421" data-name="Trazado 421" d="M12.938,31.5h3.375a.564.564,0,0,0,.563-.562V5.063a.564.564,0,0,0-.562-.562H12.938a.564.564,0,0,0-.562.563V30.938A.564.564,0,0,0,12.938,31.5Z" transform="translate(13)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                        <path id="Trazado_422" data-name="Trazado 422" d="M6.188,31.5H9.563a.564.564,0,0,0,.563-.562V20.813a.564.564,0,0,0-.562-.562H6.188a.564.564,0,0,0-.562.563V30.938A.564.564,0,0,0,6.188,31.5Z" fill="none" stroke="#ffffff" strokeWidth="1"/>
                        <path id="Trazado_423" data-name="Trazado 423" d="M19.695,31.5h3.361a.567.567,0,0,0,.57-.57V16.32a.567.567,0,0,0-.57-.57H19.695a.567.567,0,0,0-.57.57V30.93A.567.567,0,0,0,19.695,31.5Z" transform="translate(-7)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                        <path id="Trazado_424" data-name="Trazado 424" d="M25.875,9.57V30.93a.567.567,0,0,0,.57.57h3.361a.567.567,0,0,0,.57-.57V9.57a.567.567,0,0,0-.57-.57H26.445A.567.567,0,0,0,25.875,9.57Z" transform="translate(-7)" fill="none" stroke="#ffffff" strokeWidth="1"/>
                    </g>
                </svg>

            </NavLink>
            <NavLink exact to={"/proveedores"} className={'menu--item'} title={"Proveedores"}>
                <span>Proveedores</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="37.734" height="24.527" viewBox="0 0 37.734 24.527">
                    <g id="Grupo_739" data-name="Grupo 739" transform="translate(0 -106.667)">
                        <g id="Grupo_718" data-name="Grupo 718" transform="translate(5.031 123.647)">
                            <g id="Grupo_717" data-name="Grupo 717">
                                <path id="Trazado_416" data-name="Trazado 416" d="M72.04,337.067a3.773,3.773,0,1,0,3.773,3.773A3.777,3.777,0,0,0,72.04,337.067Zm0,6.289a2.516,2.516,0,1,1,2.516-2.516A2.518,2.518,0,0,1,72.04,343.356Z" transform="translate(-68.267 -337.067)" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_720" data-name="Grupo 720" transform="translate(26.414 123.647)">
                            <g id="Grupo_719" data-name="Grupo 719">
                                <path id="Trazado_417" data-name="Trazado 417" d="M362.173,337.067a3.773,3.773,0,1,0,3.773,3.773A3.777,3.777,0,0,0,362.173,337.067Zm0,6.289a2.516,2.516,0,1,1,2.516-2.516A2.518,2.518,0,0,1,362.173,343.356Z" transform="translate(-358.4 -337.067)" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_722" data-name="Grupo 722" transform="translate(23.899 111.069)">
                            <g id="Grupo_721" data-name="Grupo 721" transform="translate(0 0)">
                                <path id="Trazado_418" data-name="Trazado 418" d="M338,175.493l-5.66-8.8a.629.629,0,0,0-.529-.289H324.9a.629.629,0,0,0-.629.629v15.723a.628.628,0,0,0,.629.629h2.516v-1.258h-1.887V167.658h5.946l5.375,8.361v6.1H333.7v1.258h3.773a.628.628,0,0,0,.629-.629v-6.918A.627.627,0,0,0,338,175.493Z" transform="translate(-324.267 -166.4)" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_724" data-name="Grupo 724" transform="translate(26.414 113.585)">
                            <g id="Grupo_723" data-name="Grupo 723">
                                <path id="Trazado_419" data-name="Trazado 419" d="M359.659,206.193v-4.4h5.031v-1.258h-5.66a.629.629,0,0,0-.629.629v5.66a.628.628,0,0,0,.629.629h9.434v-1.258h-8.8Z" transform="translate(-358.401 -200.533)" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_726" data-name="Grupo 726" transform="translate(0 106.667)">
                            <g id="Grupo_725" data-name="Grupo 725" transform="translate(0 0)">
                                <path id="Trazado_420" data-name="Trazado 420" d="M24.527,106.667H.629A.629.629,0,0,0,0,107.3v20.125a.629.629,0,0,0,.629.629H5.66v-1.258h-4.4V107.925H23.9v18.867H11.949v1.258H24.527a.628.628,0,0,0,.629-.629V107.3A.629.629,0,0,0,24.527,106.667Z" transform="translate(0 -106.667)" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_728" data-name="Grupo 728" transform="translate(0.629 124.276)">
                            <g id="Grupo_727" data-name="Grupo 727" transform="translate(0 0)">
                                <rect id="Rectángulo_681" data-name="Rectángulo 681" width="3.773" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_730" data-name="Grupo 730" transform="translate(13.898 124.276)">
                            <g id="Grupo_729" data-name="Grupo 729" transform="translate(0 0)">
                                <rect id="Rectángulo_682" data-name="Rectángulo 682" width="10.691" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_732" data-name="Grupo 732" transform="translate(34.59 124.276)">
                            <g id="Grupo_731" data-name="Grupo 731" transform="translate(0 0)">
                                <rect id="Rectángulo_683" data-name="Rectángulo 683" width="2.516" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_734" data-name="Grupo 734" transform="translate(2.516 109.183)">
                            <g id="Grupo_733" data-name="Grupo 733" transform="translate(0 0)">
                                <rect id="Rectángulo_684" data-name="Rectángulo 684" width="22.012" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_736" data-name="Grupo 736" transform="translate(8.176 126.792)">
                            <g id="Grupo_735" data-name="Grupo 735">
                                <rect id="Rectángulo_685" data-name="Rectángulo 685" width="1.258" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                        <g id="Grupo_738" data-name="Grupo 738" transform="translate(29.559 126.792)">
                            <g id="Grupo_737" data-name="Grupo 737">
                                <rect id="Rectángulo_686" data-name="Rectángulo 686" width="1.258" height="1.258" rx="0.629" fill="#ffffff"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </NavLink>
            <NavLink exact to={"/gastos"} className={'menu--item'} title={"Gastos"}>
                <span>Gastos</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.205" height="27.595" viewBox="0 0 17.205 27.595">
                    <g id="Grupo_715" data-name="Grupo 715" transform="translate(-55.8)">
                        <g id="Grupo_711" data-name="Grupo 711" transform="translate(55.8)">
                            <g id="Grupo_710" data-name="Grupo 710">
                                <g id="Grupo_709" data-name="Grupo 709">
                                    <path id="Trazado_413" data-name="Trazado 413" d="M86.578,13.593A6.8,6.8,0,1,0,79.8,6.778,6.778,6.778,0,0,0,86.578,13.593Zm0-12.4a5.586,5.586,0,1,1-5.586,5.586A5.579,5.579,0,0,1,86.578,1.192Z" transform="translate(-77.566)" fill="#ffffff"/>
                                    <path id="Trazado_414" data-name="Trazado 414" d="M72.409,156.021h-.484V154.2a.588.588,0,0,0-.6-.6H59.077a.588.588,0,0,0-.6.6v2.421a.588.588,0,0,0,.6.6h.484v1.229H58.63a.588.588,0,0,0-.6.6v1.825H56.4a.588.588,0,0,0-.6.6v2.421a.588.588,0,0,0,.6.6h.67V166.3a.588.588,0,0,0,.6.6H69.914a.588.588,0,0,0,.6-.6v-2.421a.588.588,0,0,0-.6-.6h-.67v-1.229h1.639a.588.588,0,0,0,.6-.6v-1.825h.931a.588.588,0,0,0,.6-.6v-2.421A.612.612,0,0,0,72.409,156.021Zm-12.736-1.192h11.06v1.229H59.673Zm-2.681,8.416v-1.229h11.06v1.229H56.992Zm12.326,1.192v1.229H58.258v-1.229h11.06Zm.968-3.575H59.226v-1.229h11.06Zm1.527-2.421H60.753v-1.229h11.06Z" transform="translate(-55.8 -139.3)" fill="#ffffff"/>
                                </g>
                            </g>
                        </g>
                        <g id="_0cADkt" data-name="0cADkt" transform="translate(68.445 10.434) rotate(180)">
                            <g id="Grupo_712" data-name="Grupo 712" transform="translate(0)">
                                <path id="Trazado_415" data-name="Trazado 415" d="M117.653,35.495v.325q0,2.367,0,4.734a2.041,2.041,0,0,1,0,.243.451.451,0,0,1-.9.009,1.861,1.861,0,0,1-.006-.242q0-2.379,0-4.759v-.332c-.226.231-.4.409-.573.584q-.9.9-1.8,1.8a.451.451,0,0,1-.573.108.408.408,0,0,1-.216-.492.605.605,0,0,1,.158-.262q1.548-1.559,3.1-3.11a.452.452,0,0,1,.7-.007q1.556,1.552,3.107,3.108a.458.458,0,1,1-.653.627q-1.057-1.054-2.112-2.111C117.827,35.659,117.763,35.6,117.653,35.495Z" transform="translate(-113.565 -33.933)" fill="#ffffff" stroke="#ffffff" strokeMiterlimit="10" strokeWidth="0.8"/>
                            </g>
                        </g>
                    </g>
                </svg>

            </NavLink>
        </div>
    )
}

export default Menu;
