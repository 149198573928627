import React, { useContext, useEffect } from 'react';
import './styles/App.css';
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "./utils/PrivateRoute";
import UserContext from "./server/context/UserContext";
import { checkUser } from "./server/api/aberas";
import PageLogin from "./pages/login";
import PageReportes from "./pages/Reportes";
import PageIngresos from "./pages/Ingresos";
import PageGastos from "./pages/Gastos";
import PageBeneficios from "./pages/Beneficios";
import PageProveedores from "./pages/Proveedores";
import PageFacturas from "./pages/Facturas";
import moment from 'moment';
import 'moment/locale/es';
import Loader from "./components/Loader/Loader";
import axios from "axios";

//Configuracion axios
export const aberasAPI = axios.create({
    baseURL: 'https://aberasgestion.com/api',
    withCredentials: true
});

function App() {
    const { user, changeUser } = useContext(UserContext);


    useEffect(() => {
        //Configuracion de moment
        moment.locale('es');
        moment.updateLocale('es', {
            week: { dow: 1, doy: 4 },
            invalidDate: "Fecha inválida",
            weekdays: ["domingo", "lunes", "martes", "miercoles", "jueves", "viernes", "sábado"],
            weekdaysMin: ["l", "m", "x", "j", "v", "s", "d"],
            weekdaysShort: ["lun", "mar", "mie", "jue", "vie", "sab", "dom"]
        });

        //Comprobación del usuario. Esto hay que hacerlo aquí porque no tenemos acceso a las cookies.
        checkUser().then(res => {
            if (res.data.user) {
                //Cambio el usuario con el usuario que ya está logueado
                changeUser(res.data.user);
            }
        }).catch(() => console.error("Error al obtener el usuario"));
    }, []);

    return (
        <React.Fragment>
            <Loader instance={aberasAPI} />
            {/*--- DIFERENTES RUTAS DISPONIBLES ---*/}
            <Switch>
                {/*--- Comprobamos si hay un usuario logueado o no para redirigir al login o a la pagina principal ---*/}
                <Route exact path={"/login"} render={() => user ? <Redirect to={"/"} /> : <PageLogin />} />
                {/*<Route exact path={"/login"} component={PageLogin}/>*/}
                <PrivateRoute exact path='/' component={<PageReportes />} />
                <PrivateRoute exact path='/reportes' component={<PageReportes />} />
                <PrivateRoute exact path='/ingresos' component={<PageIngresos />} />
                <PrivateRoute exact path='/gastos' component={<PageGastos />} />
                <PrivateRoute exact path='/beneficios' component={<PageBeneficios />} />
                <PrivateRoute exact path='/proveedores' component={<PageProveedores />} />
                <PrivateRoute exact path='/facturas' component={<PageFacturas />} />
            </Switch>

        </React.Fragment>
    );
}

export default App;
