import React, { Dispatch, FunctionComponent } from "react";
// @ts-ignore
import inputStyles from './Input.module.css';
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { validateInput } from "../../../utils/Validators";

export type errorInput = {
    message:string,
    error:boolean
}
export type inputType = {
    value: any,
    setValue: Dispatch<any>,
    type: string,
    name: string,
    validators?: string[],
    customValidations?: Function[],
    placeHolder?: string,
    disabled?: boolean,
    required?: boolean,
    icon?: IconDefinition,
    insideIcons?: { icon:IconDefinition, color: string, onClick: () => void }[],
    outsideIcons?: { icon:IconDefinition, color: string, onClick: () => void }[]
    label?: string,
    error?: errorInput,
    onClick?: (event:React.FormEvent<HTMLInputElement>) => void,
    onBlur?: (event:React.FocusEvent<HTMLDivElement>) => void,
    onChange?: (event:React.FocusEvent<HTMLInputElement>) => void,
    style?: {},
    setError?: Dispatch<errorInput>
}

const Input: FunctionComponent<inputType> = ({value, setValue, validators, style, customValidations, onClick, onBlur, onChange, icon, insideIcons, outsideIcons, label, error, setError, type, name, placeHolder, required, disabled = false }) => {
    const handleChanges = (e:React.FocusEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setValue(val);
        if ((validators || customValidations) && setError) {
            const err = validateInput(val, validators, customValidations);
            setError(err);
        }
        if (onChange) onChange(e)
    };
    const handleBlur = (e:React.FocusEvent<HTMLInputElement>) => {
        handleChanges(e);
    };

    return (
        <div onBlur={onBlur} className={`${inputStyles.inputContainer} ${icon ? inputStyles.iconInput : ''} ${label ? inputStyles.labelInput : ''}  ${(validators || customValidations) ? inputStyles.validated : ''}`}>
            {label && <label htmlFor={name}>{label}</label>}
            {icon && <FontAwesomeIcon icon={icon} className={inputStyles.iconElement} />}
            <input
                onBlur={handleBlur}
                disabled={disabled}
                name={name}
                onFocus={onClick}
                className={`${inputStyles.input} ${icon ? inputStyles.icon : ''} ${required ? inputStyles.required : ''}`}
                value={value || ''}
                onChange={handleChanges}
                type={type}
                style={style}
                placeholder={placeHolder} />

            {insideIcons && insideIcons.length > 0 && insideIcons.map((x, i) => x && <FontAwesomeIcon key={i + 'inside'} onClick={x.onClick} style={{ cursor: 'pointer' }} icon={x.icon} color={x.color} />)}
            {outsideIcons && outsideIcons.length > 0 && outsideIcons.map((x, i) => x && <FontAwesomeIcon className={inputStyles.outsideIcon} key={i + 'outside'} onClick={x.onClick} style={{ cursor: 'pointer' }} icon={x.icon} color={x.color} />)}
            {error && <span className={inputStyles.error}>{error}</span>}
        </div>
    )
}

export default Input;
