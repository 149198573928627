import React from 'react';

import './Button.css';

const Button = (props) => {

    return (
        <div onClick={()=>{
            if(props.title !== props.selectedScreen){
                props.onPress(props.title);
            }
        }} className="Button" style={props.estilo}>
            <p className="txtBtn" style={{color: (props.title===props.selectedScreen) ? 
                '#000' : '#FDD10C'}
            }>{props.title}</p>
        </div >
    );
}



export default Button;