import React, {FunctionComponent} from 'react';
import CmptRowItem from './CmptRowItem';
import CmptChartHeader from './CmptChartHeader';
import CmptNoDataAvailable from '../NoDataAvailable/CmptNoDataAvailable';
import CSS from 'csstype';
import {Dark} from '../../styles/temas';

type Props = {
    condition?:boolean,
    source:any,
    type?:string,
    title?:string
}
const CmptTableStats : FunctionComponent<Props>= ({condition, source, type, title}) => {
    
    const {colors} = Dark;
    const box: CSS.Properties = {
        margin: '15px',
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        alignSelf: 'center'
    };

    const Tabla = () =>{
        const beneficios = source.ingresos.total - source.gastos.total;
        return (
            <div style={{display: 'grid', gridAutoRows: 'auto', gridRowGap:'8px'}} >
                <CmptRowItem title='INGRESOS' bold={true} color={colors.ingresos} data={source.ingresos.total} />
                <CmptRowItem title='GASTOS' bold={true} color={colors.gastos} data={source.gastos.total} />

                {source.grupos && source.grupos.map((element, i) => {
                    return <CmptRowItem key={i} title={element.nombre} color={element.color} data={element.total} percent={(source.ingresos.total === 0 ? 0 : element.total*100/source.ingresos.total).toString() } />
                } ) }
                
                <CmptRowItem title='BENEFICIOS' bold={true} color={colors.beneficios} data={beneficios.toString()} percent={(source.ingresos.total === 0 ? 0 :  ((beneficios*100)/source.ingresos.total)).toString() } />
            </div>
        )
    }
    return(
        <div style={ Object.assign({}, box )}>
            {title && type && <CmptChartHeader title={title} type={type} />}
            <Tabla />
        </div>
    );
};

export default CmptTableStats;
