import React from 'react';
import './Row.css';
import FormatValue from './FormatValue';
import { getFormatedDate } from './Calculos';
import { Dark } from '../../styles/temas';

const Row = ({ view, fecha, base, iva, total, par, dif, screen }) => {
    const { colors } = Dark;
    let [dia, resto, tipo] = getFormatedDate(fecha, view);
    return (
        <div className="row-container" style={{ backgroundColor: par ? '#252525' : '#383838' }}>
            <div className="fecha-container" >
                {(tipo === 'A') && <div><p className="txtGeneric" style={{ color: colors.primary }}>{dia}</p></div>}
                {(tipo === 'A') && <div><p className="txtGeneric" >{resto}</p></div>}
                {(tipo === 'B') && <div><p className="txtGeneric" >{dia}</p></div>}
            </div>
            <div className="base-container" >
                <FormatValue tipo={'base'} val={base} screen={'Diario'}
                    estilo={(base >= 0) ? (screen === 'Ingresos') ? { color: colors.accentIngresos } :
                        (screen === 'Gastos') ? { color: colors.accentGastos } : { color: colors.beneficios } :
                        (screen === 'Gastos') ? { color: colors.accentIngresos } : { color: colors.accentGastos }
                    }
                />
            </div>
            <div className="iva-container" >
                <FormatValue tipo={'iva'} val={iva} screen={'Diario'}
                    estilo={(iva < 0) ? (screen === 'Gastos') ? { color: colors.accentIngresos } : { color: colors.accentGastos } : {}}
                />
            </div>
            <div className="total-container" >
                <FormatValue tipo={'total'} val={total} screen={'Diario'}
                    estilo={(total >= 0) ? (screen === 'Ingresos') ? { color: colors.accentIngresos } :
                        (screen === 'Gastos') ? { color: colors.accentGastos } : { color: colors.beneficios } :
                        (screen === 'Gastos') ? { color: colors.accentIngresos } : { color: colors.accentGastos }
                    }
                />
            </div>
        </div>
    );
};

export default Row;