 import React, { useState, useContext, useEffect } from 'react';
import CmptProveedoresTable from '../components/Proveedores/CmptProveedoresTable';
import {Dark} from '../styles/temas';
import DateContext from '../server/context/DateContext';
import CmptCopyright from '../components/CmptCopyright';
 import {searchGastosProveedores} from "../server/api/aberas";
 import NegocioContext from "../server/context/NegociosContext";
 import SeleccionesContext from "../server/context/SeleccionesContext";

 const getDifProveedores = async (proveedoresA, proveedoresB) => {
     try {
         let temp : Array<{}> = [];
         for (let index = 0; index < proveedoresA.gastos.length; index++) {
             temp.push(
                 {
                     nombre: proveedoresA.gastos[index].nombre,
                     cantidad: proveedoresA.gastos[index].cantidad - proveedoresB.gastos[index].cantidad,
                     porcentajeIngresos: proveedoresA.gastos[index].porcentajeIngresos - proveedoresB.gastos[index].porcentajeIngresos,
                     id: proveedoresA.gastos[index].nombre
                 });
         }
         return {porcentajeIngresos: proveedoresA.porcentajeIngresos - proveedoresB.porcentajeIngresos, gastos: temp};
     }
     catch (err) {
     }
 }
const PageProveedores = () => {

    const { colors } = Dark;
    const { negocios } = useContext(NegocioContext);
    const {fechaPrincipal, fechaComparativa} = useContext(DateContext);
    const {selecciones} = useContext(SeleccionesContext);

    const [ proveedoresA, setProveedoresA] : Array<any> =useState ([]);
    const [proveedoresB, setProveedoresB] : Array<any> =useState ([]);
    const [proveedoresDif,setProveedoresDif] : Array<any> = useState([]);


    const update = async () => {
        let a = await searchGastosProveedores(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios.map(x => x.id), selecciones.gruposDeGastos, selecciones.proveedores);
        let b = await searchGastosProveedores(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios.map(x => x.id), selecciones.gruposDeGastos, selecciones.proveedores);
        setProveedoresA(a);
        setProveedoresB(b);
        let d = await getDifProveedores(a,b);
        setProveedoresDif(d);
    };

    useEffect(() => {
        if(selecciones.proveedores.length>0){
            update();
        }else{
            setProveedoresA({gastos:[],porcentajeIngresos:0});
            setProveedoresB({gastos:[],porcentajeIngresos:0});
            setProveedoresDif({gastos:[],porcentajeIngresos:0});
        }
    }, [selecciones.proveedores, fechaPrincipal, fechaComparativa]);


    return (
        <React.Fragment>

            <div style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: colors.background }}>
                {
                    proveedoresA.gastos && <CmptProveedoresTable type={'A'} proveedores={proveedoresA} />
                }
                {
                    proveedoresB.gastos &&  <CmptProveedoresTable type={'B'} proveedores={proveedoresB} />
                }
                {
                    proveedoresDif.gastos && <CmptProveedoresTable type={'DIF'} proveedores={proveedoresDif} />
                }
            </div>
        </React.Fragment>
    );
};

export default PageProveedores;
