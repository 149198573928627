import React from 'react';

import NumberFormat from 'react-number-format';
import './Row.css';
import { Dark } from '../../styles/temas';


const IngresosMedios = ({ screen, data, subheader, type, total, subheaderTotal }) => {
    const { colors } = Dark;
    return (
        <div className="ingresosContainer">
            <div style={{ marginRight: 10 }}>
                <p style={{
                    fontWeight: 'bold', color: (screen === 'Ingresos') ?
                        colors.ingresos : (screen === 'Gastos') ? colors.gastos :
                            colors.beneficios
                }}> {subheader} </p>
            </div>
            <div>
                {data.length === 1 ? <p>{data}</p> :
                    <NumberFormat
                        value={(type === 'A') ? data[0] : (type === 'B') ? data[1] : data[2]}
                        displayType={'text'}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        suffix={'€'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={formattedValue => <p className="txtGeneric"
                            style={{}}>{formattedValue}</p>}
                    />}
            </div>
            {total !== undefined && total !== null && subheaderTotal && <div style={{ marginRight: 10, marginLeft: 10 }}>
                <p style={{
                    fontWeight: 'bold', color: (screen === 'Ingresos') ?
                        colors.ingresos : (screen === 'Gastos') ? colors.gastos :
                            colors.beneficios
                }}> {subheaderTotal} </p>
            </div>}
            {total !== undefined && total !== null && <div>
                {data.length === 1 ? <p>{data}</p> :
                    <NumberFormat
                        value={total}
                        displayType={'text'}
                        decimalSeparator={','}
                        thousandSeparator={'.'}
                        suffix={'€'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={formattedValue => <p className="txtGeneric"
                            style={{}}>{formattedValue}</p>}
                    />}
            </div>}
        </div>
    );
};

export default IngresosMedios;
