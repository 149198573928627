import React, {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';

type Context = {
    selecciones: ISelecciones,
    changeSelecciones: Dispatch<SetStateAction<ISelecciones>>,
}
type ISelecciones = {
    proveedores: string[],
    gruposDeGastos: string[]
}
const SeleccionesContext = React.createContext<Context>({
                                                selecciones: {
                                                    proveedores: [],
                                                    gruposDeGastos: []
                                                },
                                                 changeSelecciones: ()=>{}
                                                });

export const SeleccionesProvider: FunctionComponent = (props) => {

    const [selecciones, setSelecciones] = useState<ISelecciones>({
                                                                proveedores: [],
                                                                gruposDeGastos: []
                                                            });

    return (
        <SeleccionesContext.Provider value={{ 
                                        selecciones: selecciones,
                                        changeSelecciones: setSelecciones
                                    }} >
            {props.children}
        </SeleccionesContext.Provider>
    )
};

export default SeleccionesContext;
