import Indice from './indices';
let moment = require('moment');
require("moment/locale/es");
moment.locale('es');


const creaBeneficios = (objA1, objB1, objA2, objB2, startA, startB, view) => {
    let resultIngresos = creaAllData(objA1, objB1, startA, startB, view);
    let resultGastos = creaAllData(objA2, objB2, startA, startB, view);
    for (let i = 0; i < resultIngresos.length - 1; i++) {
        for (let j = 0; j < resultIngresos[i].length; j++) {
            if (resultIngresos[i][j] && resultIngresos[i][j].baseImponibe && resultGastos[i][j] && resultGastos[i][j].baseImponibe) {
                resultIngresos[i][j].baseImponibe = resultIngresos[i][j].baseImponibe - resultGastos[i][j].baseImponibe;
                resultIngresos[i][j].iva = resultIngresos[i][j].iva - resultGastos[i][j].iva;
                resultIngresos[i][j].totalIngresos = resultIngresos[i][j].totalIngresos - resultGastos[i][j].totalIngresos;
            }
        }
    }

    return resultIngresos;
}

const creaAllData = (objA, objB, startA, startB, view) => {
    //datos diario
    let FECHA = dataDiario(objA, startA);
    let COMP = dataDiario(objB, startB);
    let DIF = dataCompDiario(FECHA, COMP);
    //let PROMEDIO = getGastosMedios(FECHA, COMP, DIF);
    let PROMEDIO = [objA.media, objB.media, objA.media - objB.media]
    let totalA = objA.total;
    let totalB = objB.total;
    let diffTotal = totalA - totalB;
    if (view !== 'Diario') {
        //datos dia de la semana
        let FECHA3 = dataDiaSemana(FECHA, startA);
        let COMP3 = dataDiaSemana(COMP, startA);
        let DIF3 = dataDiaSemana(DIF, startA);
        const AllData = [FECHA3, COMP3, DIF3, PROMEDIO, totalA, totalB, diffTotal];
        return AllData
    }

    const AllData = [FECHA, COMP, DIF, PROMEDIO, totalA, totalB, diffTotal];
    return AllData;
};

const dataDiario = (obj, startDate) => {
    let ARRAY = [];
    if (obj.cantidades) {
        for (let i = 0; i < obj.cantidades.length; i++) {
            let dia = moment(startDate).clone().add(i, 'days').format('dddd');
            let fecha = getDayInfo(dia).letter + ' ' + moment(startDate).clone().add(i, 'days').format('DD/MM/YYYY');
            let base = obj.cantidadesSinIva[i];
            let iva = obj.totalesIva[i];
            let total = obj.cantidades[i];
            ARRAY.push(new Indice('A' + i, fecha, base, iva, total));
        }
    }

    return ARRAY;

};

const dataDiaSemana = (obj, startDate) => {
    let ARRAY = [];
    let diaSemanaBase = [0, 0, 0, 0, 0, 0, 0];
    let diaSemanaIva = [0, 0, 0, 0, 0, 0, 0];
    let diaSemanaTotal = [0, 0, 0, 0, 0, 0, 0];
    let date = moment(startDate).clone().format('dddd');
    let offset = getDayInfo(date).offset;
    for (let i = 0; i < obj.length; i++) {
        let finalOffset = i % 7 + offset;
        if (finalOffset > 6) {
            finalOffset = finalOffset - 7;
        }
        diaSemanaBase[finalOffset] = diaSemanaBase[finalOffset] + obj[i].baseImponibe;
        diaSemanaIva[finalOffset] = diaSemanaIva[finalOffset] + obj[i].iva;
        diaSemanaTotal[finalOffset] = diaSemanaTotal[finalOffset] + obj[i].totalIngresos;
    }
    for (let i = 0; i < 7; i++) {
        let finalOffset = i % 7 + offset;
        if (finalOffset > 6) {
            finalOffset = finalOffset - 7;
        }
        ARRAY.push(new Indice('S' + i, days[i], diaSemanaBase[i], diaSemanaIva[i], diaSemanaTotal[i]));
    }

    return ARRAY;
};

const getGastosMedios = (objA, objB, objC) => {
    let array = [objA, objB, objC];
    let gastosMedios = [];
    for (let i = 0; i < array.length; i++) {
        let sum = 0;
        for (let j = 0; j < array[i].length; j++) {
            sum = sum + array[i][j].baseImponibe;
        }
        let prom = sum / array[i].length;
        gastosMedios.push(prom);
    }

    return gastosMedios;
};

const getDayInfo = (day) => {
    switch (day) {
        case 'lunes':
            return { letter: 'L', name: 'Lunes', offset: 0 };
        case 'martes':
            return { letter: 'M', name: 'Martes', offset: 1 };
        case 'miercoles':
            return { letter: 'X', name: 'Miércoles', offset: 2 };
        case 'jueves':
            return { letter: 'J', name: 'Jueves', offset: 3 };
        case 'viernes':
            return { letter: 'V', name: 'Viernes', offset: 4 };
        case 'sábado':
            return { letter: 'S', name: 'Sábado', offset: 5 };
        case 'domingo':
            return { letter: 'D', name: 'Domingo', offset: 6 };
        default:
            return { letter: '', name: '', offset: null };
    }
};

const days = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

const dataCompDiario = (arrayA, arrayB) => {

    let min = arrayA.length;
    if (min > arrayB.length) {
        min = arrayB.length;
    }
    let DIFERENCIA = [];
    for (let i = 0; i < min; i++) {
        let str1 = arrayA[i].fecha;
        let str2 = arrayB[i].fecha;

        str2 = str2.substring(1, str2.length);
        let fecha = str1 + ' - ' + str2;
        let base = arrayA[i].baseImponibe - arrayB[i].baseImponibe;
        let iva = arrayA[i].iva - arrayB[i].iva;
        DIFERENCIA.push(new Indice(i, fecha, base, iva, base + iva))
    }
    return DIFERENCIA;
};

const getWeekDay = (item) => {

    let str1 = item.fecha;
    str1 = str1.substring(0, 3);
    return str1;

};

const getFormatedDate = (fecha, type) => {

    let str1 = fecha;
    let str2 = str1.substring(0, 1);
    let str3 = str1.substring(2, fecha.length);
    if (type === 'Día de la Semana') {
        return [str1, '', 'B'];
    } else {
        return [str2, str3, 'A'];
    }

};



export { dataCompDiario, getWeekDay, getFormatedDate, creaAllData, getGastosMedios, creaBeneficios };
