import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import moment from "moment";

export type RangeDate = {
    fechaInicio: number,
    fechaFin: number
};
type Context = {
    fechaPrincipal: RangeDate,
    fechaComparativa: RangeDate,
    changeFechaPrincipal: Dispatch<SetStateAction<RangeDate>>,
    changeFechaComparativa: Dispatch<SetStateAction<RangeDate>>
}

const DateContext = React.createContext<Context>({
    fechaPrincipal: { fechaInicio: 0, fechaFin: 0 },
    fechaComparativa: { fechaInicio: 0, fechaFin: 0 },
    changeFechaPrincipal: () => { },
    changeFechaComparativa: () => { },
});

const loadDates = (): {
    fechaPrincipal: RangeDate,
    fechaComparativa: RangeDate
} => {
    const today = moment().startOf('day');
    //Existe la posibilidad de hacer moment().startOf('month') => que te devuelve el primer dia del mes.
    const firstDayofThisMonth = today.clone().subtract(today.date() - 1, 'days');
    let principal = { fechaInicio: firstDayofThisMonth.valueOf(), fechaFin: today.valueOf() }
    let comparativa = { fechaInicio: firstDayofThisMonth.clone().subtract(1, 'year').valueOf(), fechaFin: today.clone().subtract(1, 'year').valueOf() }
    if (sessionStorage.hasOwnProperty('dates')) {
        const toParse = String(sessionStorage.getItem('dates'));
        const dates = JSON.parse(toParse)
        return dates;
    }
    return {
        fechaPrincipal: principal,
        fechaComparativa: comparativa
    };
}
export const DateProvider: FunctionComponent = (props) => {

    const [fechaPrincipal, setFechaPrincipal] = useState<RangeDate>(() => loadDates().fechaPrincipal);
    const [fechaComparativa, setFechaComparativa] = useState<RangeDate>(() => loadDates().fechaComparativa);

    useEffect(() => {
        const parsed = JSON.stringify({ fechaPrincipal, fechaComparativa })
        sessionStorage.setItem('dates', parsed);
    }, [fechaComparativa, fechaPrincipal])
    return (
        <DateContext.Provider value={{ fechaPrincipal: fechaPrincipal, fechaComparativa: fechaComparativa, changeFechaComparativa: setFechaComparativa, changeFechaPrincipal: setFechaPrincipal }}>
            {props.children}
        </DateContext.Provider>
    )
};

export default DateContext;
