import React from 'react';

const CmptCopyright = ({color} : {color: string}) => {
    return <p style={{ 
        color: color,
        fontSize: 12,
        alignSelf: 'center',
        marginBottom: 15,
        marginTop: 35,
        textAlign: 'center',
    }} >© Todos los derechos reservados 2019/2020. ABERAS GESTIÓN</p>;
};

export default CmptCopyright;