import React, {useContext, useEffect, useState} from 'react';
import SearchBarSelector from "../SearchBar/SearchBarSelector";
import {getProveedores} from "../../server/api/aberas";
import SeleccionesContext from "../../server/context/SeleccionesContext";
import NegocioContext from "../../server/context/NegociosContext";

const ProveedoresSearch = () => {

    const { selecciones, changeSelecciones } = useContext(SeleccionesContext);
    const {negocios} = useContext(NegocioContext);


    const [proveedoresDisponibles,setProveedoresDisponibles]= useState<string[]>([]);
    const [todosProveedores, setAllProveedores] = useState<string[]>([]);

    useEffect(()=>{
        if(selecciones.gruposDeGastos.length>0){
            let negociosIds = negocios.map(x => x.id);
            getProveedores(negociosIds, selecciones.gruposDeGastos).then(allProveedores => {
                setAllProveedores(allProveedores);
                let prAv = proveedoresDisponibles.filter(proveedor => allProveedores.includes(proveedor));
                let prSel = selecciones.proveedores.filter(proveedor => allProveedores.includes(proveedor));
                let newProveedores = allProveedores.filter(grupo => !prAv.includes(grupo) && !prSel.includes(grupo));

                changeSelecciones({...selecciones,proveedores:prSel.concat(newProveedores)});
                setProveedoresDisponibles(prAv);
            }).catch(()=> console.error("Error al obtener los proveedores"));
        }else{
            changeSelecciones({...selecciones,proveedores:[]});
        }
    },[selecciones.gruposDeGastos]);

    const handleCurrentProveedores = ( disponibles,seleccionados ) => {
        setProveedoresDisponibles(disponibles);
        changeSelecciones({...selecciones,proveedores:seleccionados});
    };


    return <SearchBarSelector placeHolder={'Selecciona un proveedor'} list={proveedoresDisponibles} selected={selecciones.proveedores} selector={handleCurrentProveedores} allItems={todosProveedores} />
};

export default ProveedoresSearch;
