import { aberasAPI } from '../../App';
import { Dark } from '../../styles/temas';
import { ISeleccion } from '../../utils/interfaces';

export const NewGetBusinessIngresos = async (fechaInicio: number, fechaFin: number, negocios: Array<ISeleccion>) => {

    const { colors } = Dark;
    try {
        if (negocios === undefined || negocios.length === 0) {
            return { condition: false };
        }
        let temp: Array<string | number> = [];
        for (let index = 0; index < negocios.length; index++) {
            temp.push(negocios[index].id);
        }
        const res = await aberasAPI.get('/ingresos/fetchByNegocioGroupByFechas/', { params: { fInicio: fechaInicio, fFin: fechaFin, negociosIds: temp } });
        return {
            nombre: 'INGRESOS',
            condition: res.data.data.total !== 0,
            max: Math.max(...res.data.data.cantidades),
            color: colors.ingresos,
            total: res.data.data.total,
            cantidades: res.data.data.cantidades,
            fechas: res.data.data.fechas,
            cantidadesSinIva: res.data.data.cantidadesSinIva,
            totalIva: res.data.data.totalIva,
            totalSinIva: res.data.data.totalSinIva,
            totalesIva: res.data.data.totalesIva,
            media: res.data.data.media
        };
    } catch (err) {
        return { condition: false };
    }
};
export const NewGetBusinessGastos = async (fechaInicio: number, fechaFin: number, negocios: Array<ISeleccion>) => {

    const { colors } = Dark;
    try {
        if (negocios === undefined || negocios.length === 0) {
            return { condition: false };
        }

        let temp: Array<string | number> = [];
        for (let index = 0; index < negocios.length; index++) {
            temp.push(negocios[index].id);
        }
        const res = await aberasAPI.get('/gastos/fetchByNegocioGroupByFechas/', { params: { negociosIds: temp, fInicio: fechaInicio, fFin: fechaFin } });

        return {
            nombre: 'GASTOS',
            max: Math.max(...res.data.data.cantidades),
            condition: res.data.data.total !== 0 ? true : false,
            color: colors.gastos,
            total: res.data.data.total,
            cantidades: res.data.data.cantidades,
            fechas: res.data.data.fechas,
            cantidadesSinIva: res.data.data.cantidadesSinIva,
            totalIva: res.data.data.totalIva,
            totalSinIva: res.data.data.totalSinIva,
            totalesIva: res.data.data.totalesIva,
            media: res.data.data.media
        };
    } catch (err) {
        //setError('Error recopilando gastos. Sin respuesta del servidor :( ');
        return { condition: false };
    }
};
export const NewGetBusinessGruposGastos = async (fechaInicio: number, fechaFin: number, negocios: Array<ISeleccion>) => {
    try {
        if (negocios === undefined || negocios.length === 0) {
            return { condition: false };
        }
        let temp: Array<string | number> = [];
        for (let index = 0; index < negocios.length; index++) {
            temp.push(negocios[index].id);
        }
        const res = await aberasAPI.get('/gastos/fetchByNegocioGroupByGrupoGasto/', { params: { fInicio: fechaInicio, fFin: fechaFin, negociosIds: temp } });
        let b = false;
        res.data.data.gruposDeGasto.forEach((element: any, index: number) => {
            let t = Math.max(...element.cantidades);
            element.max = t;
            if (t !== 0) {
                b = true;
            }
        });
        return { condition: b, gruposGastos: res.data.data.gruposDeGasto };
    } catch (err) {
        //setError('error recopilando grupos de gastos. Sin respuesta del servidor :( ');
        return { condition: false };
    }
};



