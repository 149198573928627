import React from 'react';
import CSS from 'csstype';
import { Dark } from '../../styles/temas';
import { formatNumber } from '../../utils/formats';

const CmptProveedorItem = ({ proveedor, indicator }) => {
    const { colors } = Dark;

    const txt: CSS.Properties = {
        flex: 1,
        margin: '1px',
        fontSize: '15px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '5px'
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <p style={Object.assign(txt, { color: colors.overlayText, backgroundColor: colors.accent })}>{proveedor.nombre}</p>
            <p style={Object.assign(txt, { backgroundColor: colors.accent }, !indicator ? { color: colors.overlayText } : { color: proveedor.cantidad === 0 ? colors.overlayText : proveedor.cantidad > 0 ? colors.ingresos : colors.gastos })}>{formatNumber(parseFloat(proveedor.cantidad))}€</p>
            <p style={Object.assign(txt, { backgroundColor: colors.accent }, !indicator ? { color: colors.overlayText } : { color: proveedor.porcentajeIngresos === 0 ? colors.overlayText : proveedor.porcentajeIngresos > 0 ? colors.ingresos : colors.gastos })}>{formatNumber(parseFloat(proveedor.porcentajeIngresos))}%</p>
        </div>
    );
};

export default CmptProveedorItem;
