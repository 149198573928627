import React, {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';

export type User = {
    _id:string,
    email:string,
    cliente?:string,
    rol:string
}
type Context = {
    user:User | null,
    changeUser:Dispatch<SetStateAction<User | null>>
}
const UserContext = React.createContext<Context>({user:null, changeUser:() => {}});

export const UserProvider: FunctionComponent = (props) => {

    const [user, setUser] = useState<User | null>(null);

    return (
        <UserContext.Provider value={{user:user, changeUser:setUser}}>
            {props.children}
        </UserContext.Provider>
    )
};

export default UserContext;
