import React, { useEffect, useContext, useState } from 'react';
import { creaAllData } from '../components/Tables/Calculos';
import BodyTables from '../components/Tables/BodyTables';
import DateContext from '../server/context/DateContext';

import { NewGetBusinessIngresos } from '../server/hooks/useAberas';
import GroupSelector from '../components/Tables/GroupSelector';
import NegocioContext from "../server/context/NegociosContext";

const PageIngresos = () => {

    const { negocios } = useContext(NegocioContext);

    const { fechaPrincipal, fechaComparativa } = useContext(DateContext);
    const [loadingData, setLoadingData] = useState(false);
    const [action, setAction] = useState<any>({ type: '', payload: '' });

    const [dataInfo, setDataInfo] = useState<any>({
        DATA: null,
        condition: false,
        selectedTypeView: 'Diario'
    });


    const UpdateScreen = async (payload) => {
        let temp: any = {};

        temp.ingresosA = await NewGetBusinessIngresos(fechaPrincipal.fechaInicio, fechaPrincipal.fechaFin, negocios);
        temp.ingresosB = await NewGetBusinessIngresos(fechaComparativa.fechaInicio, fechaComparativa.fechaFin, negocios);

        let stamp = creaAllData(temp.ingresosA, temp.ingresosB, fechaPrincipal.fechaInicio, fechaComparativa.fechaInicio, payload);
        setDataInfo({ DATA: stamp, condition: true, selectedTypeView: payload });
    };

    useEffect(() => {
        setLoadingData(false);
    }, [dataInfo]);

    useEffect(() => {
        //causa de entrada
        if (loadingData) {
            switch (action.type) {
                case 'update':
                    UpdateScreen(action.payload);
                    return;
                case 'changeView':
                    UpdateScreen(action.payload);
                    return;
            }
            setAction({ type: '', payload: '' });
        }

    }, [loadingData]);

    useEffect(() => {
        if (negocios.length === 0) {
            setDataInfo({
                ...dataInfo, condition: false, DATA: null
            });
            return;
        }
        setLoadingData(true);
        setAction({ type: 'update', payload: dataInfo.selectedTypeView });
    }, [negocios, fechaPrincipal, fechaComparativa]);

    return (
        <div style={{ width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <GroupSelector view={dataInfo.selectedTypeView} setView={(newView) => {
                if (negocios.length !== 0) {
                    setLoadingData(true);
                }
                setAction({ type: 'changeView', payload: newView });
            }} />
            <BodyTables
                loading={loadingData}
                negocios={negocios}
                currentNegocios={negocios}
                view={dataInfo.selectedTypeView}
                data={dataInfo.DATA}
                screen={'Ing'}
                condition={dataInfo.condition} />
        </div>
    );
};
export default PageIngresos;

