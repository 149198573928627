import panelStyles from './Panel.module.css';
import React, { FunctionComponent, useEffect, useRef } from "react";
import gsap from  'gsap';

type Props = {
    title?: { texto: string, color: string },
    styles?: {},
    titleShowing?:boolean
}

const Panel: FunctionComponent<Props> = (props) => {
    const titleRef = useRef(document.createElement('h3'));
    useEffect(() => {
        if(titleRef.current !== null && props.titleShowing !== undefined){
            try{
                gsap.to(titleRef.current,{duration:.1,opacity:props.titleShowing ? 1 : 0,onStart:() => {
                        if(props.titleShowing){
                            titleRef.current.style.position ='relative';
                        }
                    }, onComplete: () => {
                        if(!props.titleShowing ){
                            titleRef.current.style.position ='absolute';
                        }
                    }})
            }catch(e){}
        }
    },[props.titleShowing]);
    return <div className={panelStyles.panel} style={props.styles}>
        {props.title && <h3 style={{ color: props.title.color}} ref={titleRef}>{props.title.texto}</h3>}
        {props.children}
    </div>
}

export default Panel;
