// import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React, { FunctionComponent } from 'react';
// import Input, {inputType} from './Input/Input';
// import { validateForm, hasErrors } from "../../utils/Validators";
// import ListaRadios from "./Listas/ListaRadios";
// import InputDate from './InputDate/InputDate';
// import moment from 'moment'
// import Autocomplete from '../Autocomplete/Autocomplete';


type Props = {
    onSubmit: () => void,
    onSubmitAsyncValidations?: ((values: Object) => Promise<{ property: string, error: string }>)[];
    styles?: {}
}
const Form: FunctionComponent<Props> = (props) => {
    const submit = async (e) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <div style={props.styles}>
            <form onSubmit={submit}>
                {props.children}
            </form>
        </div>
    )

};
export default Form;


/*
* <div style={styles}>
            <form onSubmit={submit}>
                {inputs.map((input, i) => {
                    const setError = (err: string) => setErrors({ ...errors, [input.property]: err });
                    let value = values ? values[input.property] : '';
                    const setValue = (val: string | number) => setValues({ ...values, [input.property]: val });
                    if (input.type === 'autocomplete') {
                        return <Autocomplete defaultValue={input.defaultValue} selectAction={input.selectAction} suggestions={input.suggestions} style={input.style} placeholder={input.placeHolder}/>
                    }
                    if (input.type === 'radio') {
                        return <ListaRadios
                            key={i + input.property}
                            validators={input.validators}
                            customValidations={input.customValidations}
                            items={input.items}
                            setError={setError}
                            setItems={input.setItems}
                            error={errors[input.property]}
                            title={input.label} />
                    }
                    if (input.type === 'date')
                        return <InputDate
                            onClose={(fecha) => {
                                setValue(moment(fecha, 'DD/MM/YYYY').unix() * 1000)
                            }}
                            input={true}
                            label={input.label}
                            dateStartSelected={moment(value).format('DD/MM/YYYY')} />

                    return <Input

                        {...input.options}
                        key={i + input.property}
                        value={value}
                        setValue={setValue}
                        type={input.type}
                        name={input.name}
                        required={input.required}
                        disabled={input.disabled}
                        validators={input.validators}
                        customValidations={input.customValidations}
                        error={errors[input.property]}
                        setError={setError}
                        label={input.label}
                    />
                })}
                {buttons && buttons.map(button => {
                    return button
                })}
            </form>
            {children}
        </div>*/
