import React, {Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useState} from 'react';
import UserContext from "./UserContext";
import {getNegocios} from "../api/aberas";

export type Negocio = {
    id:string,
    nombre:string
}
type Context = {
    negocios:Negocio[],
    changeNegocios:Dispatch<SetStateAction<Negocio[]>>,
}
const NegocioContext = React.createContext<Context>({negocios:[], changeNegocios:() => {}});

export const NegocioProvider: FunctionComponent = (props) => {

    const { user } = useContext(UserContext);

    const [negocios, setNegocios] = useState<Negocio[]>([]);

    useEffect(()=>{
        if(user){
            getNegocios(user.cliente).then(res => {
                if(res.data.data){
                    let negociosNames = res.data.data.map(negocio => {return {id:negocio._id,nombre:negocio.nombre}});
                    setNegocios(negociosNames);
                }
            });
        }
    },[user]);

    return (
        <NegocioContext.Provider value={{negocios:negocios, changeNegocios:setNegocios}}>
            {props.children}
        </NegocioContext.Provider>
    )
};

export default NegocioContext;
