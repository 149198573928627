import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import c3, { DataSeries } from 'c3';
import * as d3 from 'd3';
import 'c3/c3.min.css';
import Panel from "../Panel/Panel";
import './Grafica.css';
import gsap from 'gsap';
import moment from 'moment';

import { Dark } from '../../styles/temas';
const colors = Dark.colors;
type data = {
    nombre: string,
    color: string,
    fechas: string[],
    cantidades: number[],
    total: number
}

type graficaProps = {
    type: 'circle' | 'area',
    data: data[],
    ingresos?: number,
    beneficios?: number,
    maxWidth: number,
    maxHeight?: number,
    anchoGrafica?: number,
    id: string,
    title?: string
    colorTitle?: string
}

const Grafica: FunctionComponent<graficaProps> = (props) => {

    const graficaRef = useRef(document.createElement('div'));
    const [graficaArea, setGraficaArea] = useState<c3.ChartAPI | null>(null);

    useEffect(() => {
        try {
            if (props.type === 'area') {
                let areaGraph = generateAreaGraph(props.data, props.id);
                setGraficaArea(areaGraph);
            }
            if (props.type === 'circle' && props.anchoGrafica && props.maxWidth) {
                props.data.forEach((data, i) => {
                    const innerRadio = (props.maxWidth / 2) - ((props.anchoGrafica as number) * i);
                    const outerRadio = ((props.maxWidth / 2) - (props.anchoGrafica as number)) - ((props.anchoGrafica as number) * i);
                    let dato = props.ingresos && props.ingresos > 0 ? [data.total * 100 / (props.ingresos as number)] : [0.00];
                    generateD3CircleGraph(dato, i, props.id + '--circle-' + (i + 1), data.color, innerRadio, outerRadio, (props.maxWidth - ((props.anchoGrafica as number) * 2) * i) / 2, (props.maxWidth - ((props.anchoGrafica as number) * 2) * i) / 2);
                });
                if (props.beneficios !== undefined) {
                    let init = 0;
                    const innerRadio = (props.maxWidth / 2) - ((props.anchoGrafica as number) * props.data.length);
                    const outerRadio = ((props.maxWidth / 2) - (props.anchoGrafica as number)) - ((props.anchoGrafica as number) * props.data.length);
                    let dato = props.ingresos && props.ingresos > 0 ? [props.beneficios * 100 / (props.ingresos as number)] : [0.00];
                    generateD3CircleGraph(dato, props.data.length, props.id + '--circle-' + (props.data.length + 1), colors.beneficios, innerRadio, outerRadio, (props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length) / 2, (props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length) / 2, init);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const [currentData, setCurrentData] = useState(props.data);

    useEffect(() => {
        if (currentData !== props.data && props.type === 'circle') {
            //Si los datos guardados son diferentes a los datos pasados.
            props.data.forEach(async (data, i) => {
                let init = currentData[i] ? currentData[i].total * 100 / (props.ingresos as number) : 0;
                const innerRadio = (props.maxWidth / 2) - ((props.anchoGrafica as number) * i);
                const outerRadio = ((props.maxWidth / 2) - (props.anchoGrafica as number)) - ((props.anchoGrafica as number) * i);
                let dato = props.ingresos ? [data.total * 100 / (props.ingresos as number)] : [0.00];
                generateD3CircleGraph(dato, i, props.id + '--circle-' + (i + 1), data.color, innerRadio, outerRadio, (props.maxWidth - ((props.anchoGrafica as number) * 2) * i) / 2, (props.maxWidth - ((props.anchoGrafica as number) * 2) * i) / 2, init);
            });
            if (props.beneficios !== undefined) {
                const innerRadio = (props.maxWidth / 2) - ((props.anchoGrafica as number) * props.data.length);
                const outerRadio = ((props.maxWidth / 2) - (props.anchoGrafica as number)) - ((props.anchoGrafica as number) * props.data.length);
                let dato = props.ingresos && props.ingresos > 0 ? [props.beneficios * 100 / (props.ingresos as number)] : [0.00];
                generateD3CircleGraph(dato, props.data.length, props.id + '--circle-' + (props.data.length + 1), colors.beneficios, innerRadio, outerRadio, (props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length) / 2, (props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length) / 2);
            }
        }

        if (props.type === 'area' && graficaArea) {
            let oldIds: string[] = currentData.map(d => d.nombre).filter(data => !props.data.map(d => d.nombre).includes(data));

            let newData = props.data.filter(data => !currentData.includes(data));

            let columnsData: any[] = [];
            props.data.forEach(data => {
                let datArray: any[] = [data.nombre];
                data.cantidades.map(cant => datArray.push(cant));
                columnsData.push(datArray);
            });
            let datFechas = ["Fechas"];
            let fechasCambiadas = newData && newData[0] ? newData[0].fechas.map(fecha => moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD")) : [];
            datFechas = datFechas.concat(fechasCambiadas);
            if (datFechas.length > 1) {
                columnsData.push(datFechas);

                (graficaArea as c3.ChartAPI).load({
                    columns: columnsData
                });
                graficaArea.unload({ ids: oldIds });
            }
        }

        setCurrentData(props.data);

    }, [props.data]);

    const toggleGraph = (e) => {
        let circleId = (e.currentTarget as HTMLElement).dataset.id;
        let element: HTMLElement = document.getElementById(circleId as string) as HTMLElement;

        if (element.dataset.hidden !== 'true') {
            gsap.to(element, {
                duration: .6, opacity: 0, scale: .7, ease: 'power3.in', onComplete: () => {
                    element.dataset.hidden = 'true';
                }
            });
            e.currentTarget.style.opacity = .6;
        } else {
            gsap.to(element, {
                duration: .6, opacity: 1, scale: 1, ease: 'power3.out', onComplete: () => {
                    element.dataset.hidden = 'false';
                }
            });
            e.currentTarget.style.opacity = 1;
        }
    };
    return (
        <Panel title={{ texto: props.title ? props.title : "", color: props.colorTitle ? props.colorTitle : "" }} styles={{ margin: '16px', maxWidth: 'none' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '15px' }}>
                <div ref={graficaRef} id={props.id} style={{ display: props.type === 'circle' ? 'grid' : 'block' }} className={'grafica-aberas'}>
                    {props.type === 'circle' && props.data.map((data, i) => {
                        return <div key={i} id={props.id + '--circle-' + (i + 1)} className={'circle-container'} style={{ width: props.maxWidth - ((props.anchoGrafica as number) * 2) * i + 'px', height: props.maxWidth - ((props.anchoGrafica as number) * 2) * i + 'px', gridRow: 1, gridColumn: 1, justifySelf: 'center', alignSelf: 'center', transform: 'rotateY(180deg)' }}></div>
                    })}
                    {props.type === 'circle' && <div key={props.data.length} id={props.id + '--circle-' + (props.data.length + 1)} className={'circle-container'} style={{ width: props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length + 'px', height: props.maxWidth - ((props.anchoGrafica as number) * 2) * props.data.length + 'px', gridRow: 1, gridColumn: 1, justifySelf: 'center', alignSelf: 'center', transform: 'rotateY(180deg)' }}></div>}
                </div>
                {props.type === 'circle' && <div id={'legend-container'}>
                    {props.data.map((data, i) => {
                        return <div key={i} data-id={`${props.id}--circle-${i + 1}`} onClick={toggleGraph}>
                            <div className={'legend-item--circle'} style={{ backgroundColor: data.color }}></div>
                            <span>{data.nombre}</span>
                            <span style={{ whiteSpace: 'nowrap' }}>[{data.total > 0 && props.ingresos && props.ingresos > 0 ? (data.total * 100 / (props.ingresos as number)).toFixed(2) : 0.00} %]</span>
                        </div>
                    })}
                    {<div data-id={`${props.id}--circle-${props.data.length + 1}`} onClick={toggleGraph}>
                        <div className={'legend-item--circle'} style={{ backgroundColor: colors.beneficios }}></div>
                        <span>Beneficios</span>
                        <span style={{ whiteSpace: 'nowrap' }}>[{props.beneficios && props.ingresos && props.ingresos > 0 ? (props.beneficios * 100 / (props.ingresos as number)).toFixed(2) : 0.00} %]</span>
                    </div>}
                </div>}
            </div>
        </Panel>
    )
};

export default Grafica;


const generateAreaGraph = (data, id) => {
    let columnsData: any[] = [];
    let colors: {} = {};
    data.forEach(data => {
        let datArray: any[] = [data.nombre];
        data.cantidades.map(cant => datArray.push(cant));
        columnsData.push(datArray);

        colors[data.nombre] = data.color;
    });
    let datFechas = ["Fechas"];
    let fechasCambiadas = data[0].fechas.map(fecha => moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD"));
    datFechas = datFechas.concat(fechasCambiadas);
    columnsData.push(datFechas);

    return c3.generate({
        bindto: '#' + id,
        size: {
            width: 700
        },
        data: {
            colors: colors,
            x: "Fechas",
            columns: columnsData,
            type: 'area-spline'
        },
        axis: {
            x: {
                type: 'timeseries',
                tick: {
                    count: 8,
                    format: '%d/%m/%Y'
                }
            }
        }
    });
};

// const generateD3AreaGraph = (data,id, color, width, height) => {
//     const margin = ({top: 20, right: 20, bottom: 30, left: 30});
//     const x = d3.scaleLinear().range([margin.left, width - margin.right]);
//     const y = d3.scaleLinear().range([height - margin.bottom, margin.top]);
//     const xAxis = g => g
//         .attr("transform", `translate(0,${height - margin.bottom})`)
//         .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0))
//     const yAxis = g => g
//         .attr("transform", `translate(${margin.left},0)`)
//         .call(d3.axisLeft(y))
//         .call(g => g.select(".domain").remove())
//         .call(g => g.select(".tick:last-of-type text").clone()
//             .attr("x", 3)
//             .attr("text-anchor", "start")
//             .attr("font-weight", "bold")
//             .text(data.y))
//
//     const svg = d3.select('#'+id).append("svg").attr('height','100%').attr('width','100%');
//     // @ts-ignore
//     const mainArea : Area = d3.area().curve(d3.curveLinear).x(d => d.fecha).y0(0).y1(d => d.cantidad);
//     svg.append("path").data(data)
//         .attr("fill", color)
//         .attr("d", mainArea);
//
//     svg.append("g")
//         .call(xAxis);
//
//     svg.append("g")
//         .call(yAxis);
// }

const generateD3CircleGraph = async (data, i, id, color, radio1, radio2, trX, trY, initial?) => {

    const backgroundArc = d3.arc()
        .innerRadius(radio1)
        .outerRadius(radio2)
        .startAngle(0)
        .endAngle(function (d) { return -d / 100 * Math.PI * 2 });

    const mainArc = d3.arc()
        .innerRadius(radio1)
        .outerRadius(radio2)
        .cornerRadius(10)
        .startAngle(0)
        .endAngle(function (d) { return -d / 100 * Math.PI * 2 });
    let svg, chart;
    if (!document.getElementById(id) || !(document.getElementById(id) as HTMLElement).querySelector('svg')) {
        // no tenemos svg en ese contenedor
        svg = d3.select("#" + id).append("svg").attr('height', '100%').attr('width', '100%');
        chart = svg.append("g").data(data)
            .attr("transform", "translate(" + (trX) + "," + (trY) + ")");
        chart.append("path")
            .attr("id", id + "--bck-circle-" + i)
            .transition()
            .duration(1500)
            .attrTween("d", (d) => {
                let init = d3.interpolate(0, 100);
                return function (t) { d = init(t); return backgroundArc(d) }
            })
            .attr("fill", color)
            .attr("opacity", .3);

        chart.append("path").attr("id", id + "--chart-circle-" + i).transition()
            .duration(1500)
            .delay(300)
            .attr("fill", color)
            .attrTween('d', (d) => {
                let int = d3.interpolate(initial ? initial : 0, d);
                return function (t) {
                    d = int(t);
                    return mainArc(d)
                }
            });
    } else {
        chart = d3.select("#" + id + "--chart-circle-" + i).data(data);
        let bck = d3.select("#" + id + "--bck-circle-" + i);
        bck.transition().duration(500).attr("fill", color);
        chart.transition()
            .duration(1500)
            .attr("fill", color)
            .attrTween('d', (d) => {
                let int = d3.interpolate(initial ? initial : 0, d);
                return function (t) {
                    d = int(t);
                    return mainArc(d)
                }
            });
    }

};
