import {errorInput} from "../components/Form/Input/Input";

export const validateForm = async (values: Object, inputs: { property: string, validators?: string[], customValidations?: Function[] }[], onSubmitAsyncValidations: ((values: Object) => Promise<{ property: string, error: string }>)[] | undefined): Promise<{ [id: string]: string }> => {
    let errors = {}
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const property = input.property;
        errors[property] = validateInput(values ? values[property] : null, input.validators, input.customValidations);
    }
    if (onSubmitAsyncValidations) {
        for (let i = 0; i < onSubmitAsyncValidations.length; i++) {
            const onSubmitAsyncValidation = onSubmitAsyncValidations[i];
            let { property, error } = await onSubmitAsyncValidation(values);
            if (error && error.length > 0)
                errors[property] = errors[property] ? errors[property] + error : error;
        }
    }

    return errors;
}

export const validateInput = (value: any, validators?: string[], customValidations?: Function[]): errorInput => {
    let error = '';

    if (validators)
        for (let j = 0; j < validators.length; j++) {
            const validator = validators[j];
            error += genericValidator(value, validator);
        }
    if (customValidations)
        for (let j = 0; j < customValidations.length; j++) {
            const customValidator = customValidations[j];
            error += customValidator(value);
        }
    return {message:error,error:error!==''};
}
export const hasErrors = (errors: Object): boolean => {
    let res = false;
    for (var prop in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, prop)) {
            if (errors[prop] !== '') {
                res = true;
                break;
            }
        }
    }
    return res;
}
export const hasTrue = (object: Object): boolean => {
    let res = false;
    for (var prop in object) {
        if (Object.prototype.hasOwnProperty.call(object, prop)) {
            if (object[prop] === true) {
                res = true;
                break;
            }
        }
    }
    return res;
}
const genericValidator = (value: any, validator: string) => {
    let res = '';
    switch (validator) {
        case 'required':
            res = required(value);
            break;
        case 'email':
            res = email(value);
            break;
        case 'phone':
            res = phone(value);
            break;
        case 'cp':
            res = cp(value);
            break;
    }
    if (validator.includes('min:'))
        res = min(value, Number(validator.split(':')[1]));
    if (validator.includes('max:'))
        res = max(value, Number(validator.split(':')[1]));
    return res;
}
const required = (value: any) => {
    if ((typeof value) === 'object') {
        return hasTrue(value) ? '' : 'Este campo es obligatorio.'
    }
    if (value === undefined || value === null || value === '') return 'Este campo es obligatorio.'
    return '';
}
const email = (value: string) => {
    const esEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
    if (!esEmail) return 'Hay que introducir un email válido.'
    return '';
}
const phone = (value: string) => {
    const esPhone = new RegExp("^[0-9]{2,3}-? ?[0-9]{6,7}$");
    if (value && value.length > 0 && !esPhone.test(value)) return 'No es un teléfono válido.'
    return ''
}
const cp = (value: string) => {
    const esCP = new RegExp("^[0-9]{5}");
    if (!esCP.test(value)) return 'No es un código postal válido.';
    return ''
}
const min = (value: number, minimun: number) => {
    if (value < minimun) return 'No puede ser menor a ' + minimun + '.';
    return '';
}
const max = (value: number, maximun: number) => {
    if (value > maximun) return 'No puede ser mayor a ' + maximun + '.';
    return '';
}
