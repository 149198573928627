import React from 'react';
import NumberFormat from 'react-number-format';
import { Dark } from '../styles/temas';
import CSS from 'csstype';
import TypeInfo from './TypeInfo';


const CmptGenericHeader = ({ setShow, title, type, subheader, data, subheaderColor, total }: { setShow: any, title: string, type: string, subheader: string, data: any, subheaderColor?: string, total?: number }) => {

    const { colors } = Dark;

    const ingresos: CSS.Properties = {
        flexDirection: 'row',
        alignItems: 'flex-start'
    };
    const info: CSS.Properties = {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: '5',
        marginTop: '20',
        marginBottom: '20'
    };
    const infoLeft: CSS.Properties = {
        alignItems: 'flex-start',
        flex: 1
    };
    const txtBase: CSS.Properties = {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '14',
        marginLeft: '2',
        marginRight: '2'
    };

    return (
        <div style={info}>
            <div style={infoLeft}>
                <TypeInfo title={title} type={(type === 'A' || type === 'B') ? type : 'DIFERENCIA A-B'} />
                {subheader && <div style={ingresos}>
                    <p style={Object.assign({}, txtBase, { color: subheaderColor ? subheaderColor : colors.ingresos })}>
                        {subheader}
                    </p>
                    {data.length === 1 ? <p style={Object.assign({}, txtBase, { color: colors.overlayText })}>{data}</p> :
                        <NumberFormat
                            value={(type === 'A') ? data[0] : (type === 'B') ? data[1] : data[2]}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={'€'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={formattedValue => <p
                                style={Object.assign({}, txtBase, { color: 'white' })}
                            >{formattedValue}</p>}
                        />}
                </div>}
                {total !== null && total !== undefined && <div style={ingresos}>
                    <p style={Object.assign({}, txtBase, { color: subheaderColor ? subheaderColor : colors.ingresos })}>
                        TOTAL
                    </p>
                    <NumberFormat
                        value={total}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={'€'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={formattedValue => <p
                            style={Object.assign({}, txtBase, { color: 'white' })}
                        >{formattedValue}</p>}
                    />
                </div>}
            </div>
            {/* {type === 'A' && <CmptDateSelectorSingle setShow={setShow} iconSize = {32} datesColor = 'gray' datesOutlined={true} thisOutlined={false} datesSize={12} />}
                {type === 'B' && <CmptDateSelectorSingleExtra setShow={setShow} iconSize = {32} datesColor = 'gray' datesOutlined={true} thisOutlined={false} datesSize={12} />} */}
        </div>
    )
};

export default CmptGenericHeader;
