import React, {useState, useEffect, FunctionComponent, useRef, Dispatch} from 'react';
import ReactDOM from 'react-dom';
import moment, {Moment} from 'moment/';
// @ts-ignore
import dateStyles from "./Datepicker.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";


type Props = {
    minDate?:string,
    maxDate?:string,
    dateStart?:string,
    dateEnd?:string
    rangeDate:boolean,
    selectDateEvent:(fechaInicio:string,fechaFin:string | null) => any,
    elementRef:HTMLElement,
    setOpenPicker:Dispatch<boolean>,
    position?:string
}

type calendarDate = {
    _date:Moment,
    _thisMonth:boolean,
    label:number,
    _notClick:boolean
}
const DatePicker : FunctionComponent<Props> = ({minDate,maxDate,dateStart,dateEnd,rangeDate,selectDateEvent,elementRef,setOpenPicker,position}) => {
    moment.locale('es');
    const [startDate,setStartDate] = useState(dateStart && moment(dateStart,'DD/MM/YYYY').isValid() ? {_date:moment(dateStart,'DD/MM/YYYY')} : null);
    const [endDate,setEndDate] = useState( dateEnd ? {_date:moment(dateEnd,'DD/MM/YYYY')} : null);
    const [hoverDate,setHoverDate] = useState<calendarDate | null>(null);
    const [firstRender,setFirstRender] = useState(true);
    const [dateActive,setDateActive] = useState(dateStart  && moment(dateStart,'DD/MM/YYYY').isValid() ? moment(dateStart,'DD/MM/YYYY') : moment());

    const minMoment = moment(minDate,"DD/MM/YYYY");
    const maxMoment = moment(maxDate, "DD/MM/YYYY");


    const pickerRef = useRef<HTMLDivElement>(document.createElement('div'));

    useEffect(() => {
        if(pickerRef !== null){
            let elementInfo =  elementRef.getBoundingClientRect();
            let y = elementInfo.top + (position ===  'left' ? elementInfo.height : 0);
            let x = elementInfo.left + (position === 'left' ? 0 : elementInfo.width);
            if(pickerRef.current.getBoundingClientRect().height + y + 80 > window.innerHeight){
                y -= pickerRef.current.getBoundingClientRect().height + elementInfo.height;
            }
            pickerRef.current.style.transform = `translate(${x}px, ${y}px)`;
            setTimeout(() => {
                pickerRef.current &&  pickerRef.current.classList.add('active');
            },100);
        }
    },[pickerRef]);

    useEffect(() => {
        if(firstRender){
            setFirstRender(false);
        }
        if(!firstRender){
            if(!rangeDate && startDate){
                selectDateEvent(startDate._date.format('DD/MM/YYYY'),null);
            }
            if(startDate && endDate && rangeDate){
                selectDateEvent(startDate._date.format('DD/MM/YYYY'),endDate._date.format('DD/MM/YYYY'));
            }
        }
    },[endDate,startDate]);

    const closePicker = () => {
        setOpenPicker(false);
        pickerRef.current && pickerRef.current.classList.remove('active');
    };

    function getMonthDetails(){
        let firstDay = moment(dateActive).date(1).weekday();
        let numberOfDays = 40 -  moment(dateActive).date(40).get('date');
        return {firstDay:firstDay,numberOfDays:numberOfDays}
    }

    function getDayDetails(i:number,firstDay:number,daysOfMonth:number) : calendarDate{
        let d = i - firstDay + 1;
        let thisMonth = !(d < 1 || d > daysOfMonth);
        let date = moment(dateActive).date(d);
        let click = !dateInRange(date);
        return {_date:date, _thisMonth:thisMonth, label: date.get('date'),_notClick:click}
    }

    function getDays() : calendarDate[] {
        let days : calendarDate[] = [];
        let md = getMonthDetails();
        for (let i = 0; i < 42; i++) {
            days.push(getDayDetails(i, md.firstDay, md.numberOfDays));
        }
        return days;
    }
    function getMonth() {
        return dateActive.format('MMMM');
    }
    function getYear(){
        return dateActive.format('YYYY');
    }
    function dateInRange(date:Moment){
        let res = true;
        if(minMoment.isValid() && date.isBefore(minMoment,'date')) res = false ;
        if(maxMoment.isValid() && date.isAfter(maxMoment,'date')) res = false;
        return res;
       // return !((minMoment.isValid() || maxMoment.isValid()) && (date.isBefore(minMoment) || date.isAfter(maxMoment)));
    }

    const handleDates = (day:calendarDate) => {
        if(dateInRange(day._date)){
            if(startDate && !endDate && rangeDate){
                if(day._date.isBefore(startDate._date)){
                    setStartDate(day);
                }
                if(day._date.isSameOrAfter(startDate._date) && rangeDate){
                    setEndDate(day);
                }
            }else{
                setStartDate(day);
                setEndDate(null);
            }
        }
    };

    const getStylesDay = (day:calendarDate) => {
        let styles = dateStyles.day;
        if(!day._thisMonth) styles = styles.concat(' ',dateStyles.disabled);
        if(day._notClick) styles = styles.concat(' ',dateStyles.notClick);
        if(day._date.isSame(moment(),'date')) styles = styles.concat(' ',dateStyles.today);
        if(rangeDate && startDate && endDate && day._date.isBetween(startDate._date,endDate._date, 'date', '[]')) styles = styles.concat(' ', dateStyles.inRange);
        if (startDate &&  moment.isMoment(startDate._date)){
            if(startDate._date.isSame(day._date,'date')) styles = styles.concat(' ',dateStyles.startDate);
            if(rangeDate && hoverDate && moment.isMoment(hoverDate._date)){
                if(day._date.isBetween(startDate._date,hoverDate._date,'date','[]'))  styles = styles.concat(' ', dateStyles.inRange) ;
                if(day._date.isSame(hoverDate._date,'date')) styles = styles.concat(' ', dateStyles.hoveredDate);
            }
        }
        if(rangeDate && endDate && moment.isMoment(endDate._date) && endDate._date.isSame(day._date,'date')) styles = styles.concat(' ', dateStyles.endDate) ;

        return styles;
    };

    const handleHover =(day: calendarDate) => {
        if(startDate && !endDate && rangeDate && dateInRange(day._date)){
            setHoverDate(day);
        }
    };
    const pickerElement =  <div className={dateStyles.pickerContainer}>
        <div className={dateStyles.pickerBackground} onClick={closePicker}></div>
        <div className={dateStyles.calendar} ref={pickerRef}>
            <div className={dateStyles.title}>
                <div style={{cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', alignSelf: 'stretch'}} onClick={() => setDateActive(moment(dateActive).subtract(1,'M'))}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <span className={dateStyles.unselectable} style={{flex:1, textAlign: 'center'}} >{getMonth()} - {getYear()}</span>
                <div style={{cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', alignSelf: 'stretch'}} onClick={() => setDateActive(moment(dateActive).add(1,'M'))}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
            </div>
            <div className={dateStyles.container}>
                <div className={dateStyles.header}>
                    {moment.weekdaysMin().map((dayLabel,i) => {
                        return <div className={dateStyles.unselectable} key={i}>{dayLabel}</div>
                    })}
                </div>
                <div className={dateStyles.body}>
                    {getDays().map((day,i) => {
                        return <div key={i} className={`${getStylesDay(day)} ${dateStyles.unselectable}`} onClick={() => handleDates(day)} onMouseOver={() => handleHover(day)}>{day.label}</div>
                    })}
                </div>
            </div>

        </div>
    </div>;

    return ( ReactDOM.createPortal(pickerElement,(document.getElementById('main') as HTMLElement)));
};

export default DatePicker;
