import numeral from 'numeral'
export const formatNumber = (number: number): string => {
    if (isNaN(number)) return '0.00'
    if ('' + number === 'Infinity') return '∞'
    let reverse = numeral(number).format('0,0.00')
    let decimals = ''
    let entero = reverse;
    if (reverse.includes('.')) {
        entero = reverse.split('.')[0]
        decimals = reverse.split('.')[1]
    }
    entero = entero.replace(',', '.')
    return entero + ',' + decimals
}