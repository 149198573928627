import React from 'react';
import { Dark } from '../styles/temas';
import CSS from 'csstype';

const TypeInfo = ({ title, type }: { title?: string, type?: string }) => {
    const { colors } = Dark;

    const coolTitle: CSS.Properties = {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '6px'
    };
    const coolTitleLeft: CSS.Properties = {
        flex: 1,
        textAlign: 'left',
        fontSize: '20px',
        color: colors.overlayText
    };
    const coolTitleRight: CSS.Properties = {
        margin: '4px',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.primary
    };

    return <div style={coolTitle}>
        <p style={coolTitleLeft}>{title}</p>
        {(type !== 'Diferencia') && <p style={coolTitleRight}> ({type}) </p>}
        {(type === 'Diferencia') && <p style={coolTitleRight}> DIFERENCIA (A-B) </p>}
    </div>

};

export default TypeInfo;
