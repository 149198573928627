import React from 'react';
import NumberFormat from 'react-number-format';
import './FormatValue.css';

const FormatValue = props => {
    return <NumberFormat
        value={props.val}
        displayType={'text'}        
        suffix={'€'}
        decimalScale={2}
        decimalSeparator={','}
        thousandSeparator={'.'}
        fixedDecimalScale={true}
        renderText={formattedValue => <p className="txtGeneric"
            style={props.estilo}>{formattedValue}</p>}
    />
};

export default FormatValue;