import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import {UserProvider} from "./server/context/UserContext";
import {DateProvider} from "./server/context/DateContext";
import {NegocioProvider} from "./server/context/NegociosContext";

ReactDOM.render(
  <BrowserRouter>
      <UserProvider>
          <DateProvider>
              <NegocioProvider>
                  <App />
              </NegocioProvider>
          </DateProvider>
      </UserProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
