import React, {useState, useEffect, useRef, FunctionComponent} from 'react';
import { faTimes, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import autoStyles from "./SearchBarSelector.module.css"; 
import Autocomplete from '../Autocomplete/Autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../Modal/Modal";
import Checkbox from "../Form/Checkbox/Checkbox";

const FindAndRemove = (lista, nombre) => {
    return lista.filter(function (value, index, arr) { return (value.nombre ? value.nombre : value) !== nombre; });
}
const FindAndAdd = (lista, nombre) => {
    return lista.filter(function (value, index, arr) { return (value.nombre ? value.nombre : value) === nombre; });
}

type Props = {
    placeHolder:string,
    list:any[],
    selected:any[],
    selector:(d,s) => void,
    allItems?:any[]
}
const SearchBarSelector:FunctionComponent<Props> = ({placeHolder, list, selected, selector, allItems}) => {

    const [disponibles, setDisponibles] = useState<Array<string>>([]);

    const [overflowItems, setOverflowItems] = useState(false);
    const [modalSelections, setModalSelections] = useState(false);
    const itemsContainer = useRef<HTMLDivElement>(document.createElement('div'));
    const [resetAuto, setResetAuto] = useState(0);


    const buildDisponibles = () => {
        if(list == null){
            return;
        }
        setDisponibles(list.map(x => x.nombre ? x.nombre : x));
    };

    useEffect(() => {
        if(itemsContainer.current.offsetWidth < itemsContainer.current.scrollWidth && !overflowItems){
            setOverflowItems(true);
        }
        if(itemsContainer.current.offsetWidth >= itemsContainer.current.scrollWidth && selected.length<4 && overflowItems){
            setOverflowItems(false);
        }

    },[selected]);
    
    const handleRemoveSelected = (element) => {
        const d : Array<{}> = [element].concat(list.slice());
        const s : Array<{}> = FindAndRemove(selected, element.nombre ? element.nombre : element);
        selector(d, s);
    };
    const handleSelect = (nombre) => {
        const found = FindAndAdd(list, nombre);
        const copy = selected.slice();

        const s : Array<{}> = found.concat(copy);
        const d : Array<{}> = FindAndRemove(list, nombre);
        setResetAuto(resetAuto+1);
        selector(d, s);
    };

    useEffect(()=>{
        buildDisponibles();
    },[list, selected]);

    const handleChangeCheckBox = (option) => {
        if(selected.includes(option)){
            handleRemoveSelected(option)
        }else{
            handleSelect(option)
        }
    }

    return (
        <div className={autoStyles.container} >
            <div className={autoStyles.selectedGroup} ref={itemsContainer} >
                {!overflowItems  ? selected.map((element, i)=>{
                    return <div key={i} className={autoStyles.selectedItem}  onClick={()=> handleRemoveSelected(element)}>
                        <span className={autoStyles.selectedItemTxt} >{element.nombre ? element.nombre : element}</span>
                        <div className={autoStyles.iconItem}>
                            <FontAwesomeIcon size={'lg'} className={autoStyles.closeIcon} icon={faTimes} />
                        </div>
                    </div>
                }) : selected.length>0 && <div className={autoStyles.selectedItem} onClick={()=> setModalSelections(true)}>
                    <span className={autoStyles.selectedItemTxt} >{selected[0].nombre ? selected[0].nombre : selected[0]}</span>
                    <div   className={autoStyles.iconItem} style={{width:'27px',height:'27px', top:'50%', transform:'translate(20px, -50%)', padding:'3px'}}>
                        <FontAwesomeIcon className={autoStyles.closeIcon} icon={faPlus} style={{fontSize:'.7em'}}/>
                        <span style={{marginLeft:'3px', fontSize:'1.2em', letterSpacing:'1px'}}>{selected.length}</span>
                    </div>
                </div>}
            </div>
            <div className={autoStyles.autocomplete} >
                <Autocomplete
                            suggestions = {disponibles}
                            selectAction = {(nombre)=> handleSelect(nombre) }
                            icon={faSearch}
                            placeholder = {placeHolder}
                            disabled = {list === null}
                            reset={resetAuto}
                            //stayText?: boolean,
                            //style= {{alignSelf: 'stretch'}}
                            //onChange?: () => void 
                            />
                {list === null && <div className={autoStyles.disabledMask} ></div>}
            </div>

            {
                modalSelections && <Modal open={modalSelections} setOpen={setModalSelections}>
                    <span style={{cursor:'pointer', textDecoration:'underline'}} onClick={()=>{selector(list.concat(selected),[]); setModalSelections(false);}}><b>Limpiar filtros</b></span>
                    {
                        allItems && allItems.length>0 && allItems.map((option,i) => {
                            return <div key={i} style={{margin:'8px 0'}}>
                                <Checkbox type={"checkbox"} name={"selected"} label={option.nombre ? option.nombre : option} checked={selected.includes(option)} onChange={()=>handleChangeCheckBox(option)}/>
                            </div>
                        })
                    }
                </Modal>
            }
        </div>
    );
};

export default SearchBarSelector;
