import React, { useState, useEffect } from 'react';

import './Row.css';
import Button from './Button';

const GroupSelector = ({ view, setView }) => {
    const [selectedScreen, setSelectedScreen] = useState(view);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!count) {
            setCount(1);
            return;
        }

        setView(selectedScreen);

    }, [selectedScreen]);

    return <div className="selectorContainer">
        <Button onPress={(newValue)=>{
            setSelectedScreen(newValue);
        }} estilo={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: (selectedScreen==='Diario') ? '#FDD10C' : 'transparent'
        }} title={'Diario'} selectedScreen={selectedScreen}/>
        <Button onPress={(newValue)=>{
            setSelectedScreen(newValue);
        }} estilo={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            backgroundColor: (selectedScreen!=='Diario') ? '#FDD10C' : 'transparent'
        }} title={'Día de la Semana'} selectedScreen={selectedScreen}/>
    </div>
};

export default GroupSelector;