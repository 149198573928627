import React from 'react';

import TableHeader from './TableHeader';
import Row from './Row';

const GenericTable = ({ data, view, screen, dif }) => {
    return (
        <div style={{ width: '100%', alignSelf: 'center', flexDirection: 'column' }}>
            <TableHeader screen={screen} />
            {
                data.map((item, index) => {
                    let par = false;
                    if (index % 2 === 0) {
                        par = true;
                    }
                    return (
                        <Row key={item.fecha+screen+index} view={view} fecha={item.fecha} base={item.baseImponibe} iva={item.iva}
                            total={item.totalIngresos} screen={screen} par={par} dif={dif}
                        />
                    );
                })
            }



        </div>
    );
};

export default GenericTable;