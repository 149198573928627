import React from 'react';
import CSS from 'csstype';
import { formatNumber } from '../../utils/formats'
const CmptRowItem = ({ title, color, data, percent, bold }: { title: string, color: string, data: string, percent?: string, bold?: boolean }) => {

    const pnl: CSS.Properties = {
        //width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,160px)',
        gridColumnGap: '8px',
        color: 'var(--black)'
    };
    const item: CSS.Properties = {
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: color
    };

    return (
        <div style={pnl}>
            <div style={item}>
                {bold ? <b>{title.toUpperCase()}</b> : title.toUpperCase()}
            </div>
            <div style={Object.assign({}, item, { backgroundColor: color })} >
                {formatNumber(parseFloat(data))} €
            </div>
            <div style={Object.assign({}, item, { opacity: percent ? 1 : 0 })}>
                {formatNumber(parseFloat(percent ? percent : ''))}%
            </div>
        </div>
    );
};

export default CmptRowItem;
