import {ITema} from '../utils/interfaces';

export const Dark : ITema = {
    colors: {
        primary: '#FDD10C',     //cambiar estos colores o modificarlos
        accent: '#656565',
        background: '#1c1c1c',
        light: '#FFFFFF',
        ingresos: '#C3D69B',
        gastos: '#D99694',
        beneficios: '#55B7CE',
        darkBlue: '#446DF6',
        divider: '#4b4b4ba0',
        fireOpal: '#E85F5C',
        baseIngresos: '#C3D69B',
        accentIngresos: '#C3D69B',
        beige: '#efe6c6',
        baseGastos: '#D99694',
        accentGastos: '#D99694',
        darkGray: '#4a4a4a',
        white: '#FFFFFF',
        row1: '#252525',
        row2: '#383838',
        overlay: '#f2f2f255',
        overlayText: '#FFFFFF'
    }
}
